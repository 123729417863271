import { Route, Routes, Navigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Sidebar from "../components/SideBar";
import AddTeamMember from "../components/custom/TeamMember/AddTeamMember";
import ViewTeamMembers from "../components/custom/TeamMember/ViewTeamMembers";
import EditTeamMember from "../components/custom/TeamMember/EditTeamMember";
import TeamMemberDetails from "../components/custom/TeamMember/TeamMemberDetails";

function TeamMember() {
  return (
    <div>
      <Navbar />
      <Sidebar />
      <Routes>
        <Route path="/add" element={<AddTeamMember />} />
        <Route path="/view" element={<ViewTeamMembers />} />
        <Route path="/edit/:id" element={<EditTeamMember />} />
        <Route path="/:id" element={<TeamMemberDetails />} />
        <Route path="/*" element={<Navigate to="/view" replace />} />
      </Routes>
    </div>
  );
}
export default TeamMember;
