import axios from "axios";
import {
  getFaqsSuccess,
  getFaqSuccess,
  createFaqSuccess,
  updateFaqSuccess,
  deleteFaqSuccess,
  faqError,
} from "../reducers/faqReducer";

const API_URL = process.env.REACT_APP_API_URL;

const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  return token ? { Authorization: `Bearer ${token}` } : {};
};

export const getFaqs = () => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}/faqs`, {
      headers: getAuthHeader(),
    });
    dispatch(getFaqsSuccess(res.data.data));
  } catch (err) {
    dispatch(faqError());
  }
};

export const getFaq = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}/faqs/${id}`, {
      headers: getAuthHeader(),
    });
    dispatch(getFaqSuccess(res.data.data));
  } catch (err) {
    dispatch(faqError());
  }
};

export const createFaq = (faqData) => async (dispatch) => {
  try {
    const res = await axios.post(`${API_URL}/faqs`, faqData, {
      headers: {
        ...getAuthHeader(),
        "Content-Type": "application/json",
      },
    });

    if (res.data.success) {
      dispatch(createFaqSuccess(res.data.data));
      // Redirect after successful creation
      window.location.href = "/faqs/view";
    } else {
      throw new Error(res.data.error || "Failed to create FAQ");
    }
  } catch (err) {
    dispatch(faqError(err.response?.data?.error || err.message));
  }
};

export const updateFaq = (id, faqData) => async (dispatch) => {
  try {
    const res = await axios.put(`${API_URL}/faqs/${id}`, faqData, {
      headers: getAuthHeader(),
    });

    if (res.data.success) {
      dispatch(updateFaqSuccess(res.data.data));
      // Redirect after successful update
      window.location.href = "/faqs/view";
    } else {
      throw new Error(res.data.error || "Failed to update FAQ");
    }
  } catch (err) {
    dispatch(faqError());
  }
};

export const deleteFaq = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(`${API_URL}/faqs/${id}`, {
      headers: getAuthHeader(),
    });
    if (res.data.success) {
      dispatch(deleteFaqSuccess(id));
    } else {
      throw new Error(res.data.error || "Failed to delete FAQ");
    }
  } catch (err) {
    dispatch(faqError());
  }
};
