import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getService, updateService } from "../../../redux/actions/service";
import ContentHeader from "../../ContentHeader";
import { useParams, useNavigate } from "react-router-dom";

function EditService() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { service, loading, error } = useSelector((state) => state.service);

  const [formData, setFormData] = useState({
    bankingType: "",
    title: "",
    serviceImage: null,
    tabs: [
      {
        content: {
          title: "",
          description: "",
          pageLink: "",
          item1Title: "",
          items1: [],
          item2Title: "",
          items2: [],
        },
      },
    ],
    status: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  useEffect(() => {
    dispatch(getService(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (service) {
      setFormData({
        bankingType: service.bankingType || "",
        title: service.title || "",
        tabs: service.tabs.map((tab) => ({
          content: {
            title: tab.content.title || "",
            description: tab.content.description || "",
            pageLink: tab.content.pageLink || "",
            item1Title: tab.content.item1Title || "",
            items1: tab.content.items1 || [""],
            item2Title: tab.content.item2Title || "",
            items2: tab.content.items2 || [""],
          },
        })),
        status: service.status || "",
      });
      setCurrentImage(service.bannerImage);
    }
  }, [service]);

  const { bankingType, title, serviceImage, tabs, status } = formData;

  const onChange = (e) => {
    if (e.target.name === "serviceImage") {
      setFormData({ ...formData, [e.target.name]: e.target.files[0] });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const onTabChange = (index, field, value) => {
    setFormData((prevState) => {
      const newTabs = prevState.tabs.map((tab, i) => {
        if (i !== index) return tab;
        const [parent, child] = field.split(".");
        if (child) {
          return {
            ...tab,
            [parent]: {
              ...tab[parent],
              [child]: value,
            },
          };
        }
        return {
          ...tab,
          [field]: value,
        };
      });
      return { ...prevState, tabs: newTabs };
    });
  };

  const onItemChange = (index, itemIndex, itemList, value) => {
    setFormData((prevState) => {
      const newTabs = prevState.tabs.map((tab, i) => {
        if (i !== index) return tab;
        const newItems = [...tab.content[itemList]];
        newItems[itemIndex] = value;
        return {
          ...tab,
          content: {
            ...tab.content,
            [itemList]: newItems,
          },
        };
      });
      return { ...prevState, tabs: newTabs };
    });
  };

  const addItem = (index, itemList) => {
    setFormData((prevState) => {
      const newTabs = prevState.tabs.map((tab, i) => {
        if (i !== index) return tab;
        return {
          ...tab,
          content: {
            ...tab.content,
            [itemList]: [...tab.content[itemList], ""],
          },
        };
      });
      return { ...prevState, tabs: newTabs };
    });
  };

  const removeItem = (index, itemIndex, itemList) => {
    setFormData((prevState) => {
      const newTabs = prevState.tabs.map((tab, i) => {
        if (i !== index) return tab;
        return {
          ...tab,
          content: {
            ...tab.content,
            [itemList]: tab.content[itemList].filter((_, i) => i !== itemIndex),
          },
        };
      });
      return { ...prevState, tabs: newTabs };
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("bankingType", bankingType);
      formDataToSend.append("title", title);
      formDataToSend.append("status", status);
      if (serviceImage) {
        formDataToSend.append("serviceImage", serviceImage);
      }
      formDataToSend.append("tabs", JSON.stringify(tabs));

      await dispatch(updateService(id, formDataToSend));
      // If successful, the updateService action will handle the redirect
    } catch (err) {
      console.error("Update Service Error:", err);
      // Set an error state to display to the user
      error(err.message || "Failed to update service");
    } finally {
      setIsSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border text-dark" role="status"></div>
      </div>
    );
  }

  return (
    <div className="content-wrapper bg-light d-flex justify-content-center">
      <div className="container py-5">
        <ContentHeader title="Edit Banking Service" link="/services/view" />
        <section className="content">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card card-dark">
                <div className="card-header">
                  <h3 className="card-title mb-0">Edit Banking Service</h3>
                </div>
                <form onSubmit={onSubmit}>
                  <div className="card-body">
                    {/* Banking Type and Title */}
                    <div className="form-row">
                      <div className="form-group col-lg-6">
                        <label htmlFor="bankingType" className="form-label">
                          Banking Type
                        </label>
                        <select
                          name="bankingType"
                          id="bankingType"
                          value={bankingType}
                          onChange={onChange}
                          className="form-control"
                        >
                          <option value="Personal">Personal</option>
                          <option value="SME">SME</option>
                          <option value="Business">Business</option>
                        </select>
                      </div>
                      <div className="form-group col-lg-6">
                        <label htmlFor="title" className="form-label">
                          Title
                        </label>
                        <input
                          type="text"
                          name="title"
                          id="title"
                          value={title}
                          onChange={onChange}
                          className="form-control"
                          required
                        />
                      </div>
                    </div>

                    {/* Tabs Section */}
                    {tabs.map((tab, index) => (
                      <div
                        key={index}
                        className="mb-4 p-4 border rounded bg-white "
                      >
                        <h4 className="mb-3">Tab {index + 1}</h4>
                        <div className="form-row g-3">
                          <div className="form-group col-lg-6">
                            <label
                              htmlFor={`tabTitle_${index}`}
                              className="form-label"
                            >
                              Tab Title
                            </label>
                            <input
                              type="text"
                              name={`tabTitle_${index}`}
                              id={`tabTitle_${index}`}
                              value={tab.content.title || ""}
                              onChange={(e) =>
                                onTabChange(
                                  index,
                                  "content.title",
                                  e.target.value
                                )
                              }
                              className="form-control"
                            />
                          </div>

                          <div className="form-group col-lg-6">
                            <label
                              htmlFor={`tabLink_${index}`}
                              className="form-label"
                            >
                              Page Link
                            </label>
                            <select
                              name={`tabLink_${index}`}
                              id={`tabLink_${index}`}
                              value={tab.content.pageLink || ""}
                              onChange={(e) =>
                                onTabChange(
                                  index,
                                  "content.pageLink",
                                  e.target.value
                                )
                              }
                              className="form-control"
                            >
                              <option value="">Select a page link</option>
                              {bankingType === "Personal" && (
                                <>
                                  <option value="personal-current-account">
                                    Personal Current Account
                                  </option>
                                  <option value="personal-saving-Account">
                                    Personal Saving Account
                                  </option>
                                  <option value="personal-fixed-deposit-account">
                                    Personal Fixed Deposit Account
                                  </option>
                                  <option value="sms-alert-service">
                                    SMS Alert Service
                                  </option>
                                </>
                              )}
                              {bankingType === "Business" && (
                                <>
                                  <option value="micro-business-loan">
                                    Micro Business Loan
                                  </option>
                                  <option value="small-business-loan">
                                    Small Business Loan
                                  </option>
                                  <option value="business-current-account">
                                    Business Current Account
                                  </option>
                                  <option value="business-savings-account">
                                    Business Savings Account
                                  </option>
                                  <option value="business-fixed-deposit">
                                    Business Fixed Deposit
                                  </option>
                                </>
                              )}
                              {bankingType === "SME" && (
                                <>
                                  <option value="business-loans">
                                    Business Loans
                                  </option>
                                  <option value="business-current-account">
                                    Business Current Account
                                  </option>
                                  <option value="business-savings-account">
                                    Business Savings Account
                                  </option>
                                </>
                              )}
                            </select>
                          </div>

                          <div className="form-group col-lg-12">
                            <label
                              htmlFor={`tabDescription_${index}`}
                              className="form-label"
                            >
                              Tab Description
                            </label>
                            <textarea
                              name={`tabDescription_${index}`}
                              id={`tabDescription_${index}`}
                              value={tab.content.description || ""}
                              onChange={(e) =>
                                onTabChange(
                                  index,
                                  "content.description",
                                  e.target.value
                                )
                              }
                              className="form-control"
                              rows="6"
                            />
                          </div>

                          <div className="form-group col-lg-6">
                            <label
                              htmlFor={`item1Title_${index}`}
                              className="form-label"
                            >
                              Item 1 Title
                            </label>
                            <input
                              type="text"
                              name={`item1Title_${index}`}
                              id={`item1Title_${index}`}
                              value={tab.content.item1Title || ""}
                              onChange={(e) =>
                                onTabChange(
                                  index,
                                  "content.item1Title",
                                  e.target.value
                                )
                              }
                              className="form-control"
                            />
                          </div>

                          <div className="form-group col-lg-6">
                            <label
                              htmlFor={`item2Title_${index}`}
                              className="form-label"
                            >
                              Item 2 Title
                            </label>
                            <input
                              type="text"
                              name={`item2Title_${index}`}
                              id={`item2Title_${index}`}
                              value={tab.content.item2Title || ""}
                              onChange={(e) =>
                                onTabChange(
                                  index,
                                  "content.item2Title",
                                  e.target.value
                                )
                              }
                              className="form-control"
                            />
                          </div>

                          {/* Item 1 List */}
                          <div className="form-group col-lg-6">
                            <label
                              htmlFor={`item1_${index}`}
                              className="form-label"
                            >
                              Item 1 List
                            </label>
                            {(tab.content.items1 || []).map(
                              (item, itemIndex) => (
                                <div
                                  key={itemIndex}
                                  className="input-group mb-3"
                                >
                                  <input
                                    type="text"
                                    name={`item1_${index}_${itemIndex}`}
                                    id={`item1_${index}_${itemIndex}`}
                                    value={item}
                                    onChange={(e) =>
                                      onItemChange(
                                        index,
                                        itemIndex,
                                        "items1",
                                        e.target.value
                                      )
                                    }
                                    className="form-control"
                                  />
                                  <div className="input-group-append">
                                    <button
                                      className="btn btn-danger"
                                      type="button"
                                      onClick={() =>
                                        removeItem(index, itemIndex, "items1")
                                      }
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              )
                            )}
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => addItem(index, "items1")}
                            >
                              Add Item
                            </button>
                          </div>

                          {/* Item 2 List */}
                          <div className="form-group col-lg-6">
                            <label
                              htmlFor={`item2_${index}`}
                              className="form-label"
                            >
                              Item 2 List
                            </label>
                            {(tab.content.items2 || []).map(
                              (item, itemIndex) => (
                                <div
                                  key={itemIndex}
                                  className="input-group mb-3"
                                >
                                  <input
                                    type="text"
                                    name={`item2_${index}_${itemIndex}`}
                                    id={`item2_${index}_${itemIndex}`}
                                    value={item}
                                    onChange={(e) =>
                                      onItemChange(
                                        index,
                                        itemIndex,
                                        "items2",
                                        e.target.value
                                      )
                                    }
                                    className="form-control"
                                  />
                                  <div className="input-group-append">
                                    <button
                                      className="btn btn-danger"
                                      type="button"
                                      onClick={() =>
                                        removeItem(index, itemIndex, "items2")
                                      }
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              )
                            )}
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => addItem(index, "items2")}
                            >
                              Add Item
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}

                    {/* Status and Image fields */}
                    <div className="form-row">
                      <div className="form-group col-lg-6">
                        <label htmlFor="status" className="form-label">
                          Status
                        </label>
                        <select
                          name="status"
                          id="status"
                          value={status}
                          onChange={onChange}
                          className="form-control"
                        >
                          <option value="approve">Approve</option>
                          <option value="pending">Pending</option>
                        </select>
                      </div>
                      <div className="form-group col-lg-6">
                        <label htmlFor="serviceImage" className="form-label">
                          Service Image
                        </label>
                        <input
                          type="file"
                          name="serviceImage"
                          id="serviceImage"
                          onChange={onChange}
                          className="form-control"
                          accept="image/*"
                        />
                        {currentImage && (
                          <img
                            src={currentImage}
                            alt="Current Service"
                            className="mt-2"
                            style={{ maxWidth: "200px" }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="d-flex justify-content-between">
                      <button
                        onClick={() => navigate(-1)}
                        className="btn btn-secondary btn-md"
                      >
                        <i className="fas fa-times mr-2"></i>Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-success btn-md"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Updating...
                          </>
                        ) : (
                          <>
                            <i className="fas fa-save mr-1"></i> Update Service
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default EditService;
