import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { getTeamMember } from "../../../redux/actions/teamMember";
import ContentHeader from "../../ContentHeader";

function TeamMemberDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { teamMember, loading } = useSelector((state) => state.teamMember);

  useEffect(() => {
    dispatch(getTeamMember(id));
  }, [dispatch, id]);

  if (loading || !teamMember) {
    return <div>Loading...</div>;
  }

  const {
    name,
    position,
    teamMemberImage,
    bio,
    socialMediaLinks,
    boardType,
    status,
  } = teamMember;

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border text-dark" role="status"></div>
      </div>
    );
  }

  return (
    <div className="content-wrapper bg-light">
      <ContentHeader title="Team Member Details" link="/team-members/view" />
      <section className="content">
        <div className="container" py-5>
          <div className="row justify-content-center">
            <div className="col-md-12 shadow-sm">
              <div className="card card-dark">
                <div className="card-header">
                  <h3 className="card-title">{name}</h3>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4">
                      <img
                        src={`${teamMemberImage}`}
                        alt="Profile"
                        className="img-fluid rounded"
                      />
                    </div>
                    <div className="col-md-8">
                      <table className="table table-bordered table-hover">
                        <tbody>
                          <tr>
                            <th className="bg-light">Name</th>
                            <td>{name}</td>
                          </tr>
                          <tr>
                            <th className="bg-light">Position</th>
                            <td>{position}</td>
                          </tr>
                          <tr>
                            <th className="bg-light">Board Type</th>
                            <td>{boardType}</td>
                          </tr>
                          <tr>
                            <th className="bg-light">Linkedin</th>
                            <td>
                              {socialMediaLinks && socialMediaLinks.linkedin ? (
                                <a
                                  href={socialMediaLinks.linkedin}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {socialMediaLinks.linkedin}
                                </a>
                              ) : (
                                "N/A"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <th className="bg-light">Twitter (X) </th>
                            <td>
                              {socialMediaLinks && socialMediaLinks.twitter ? (
                                <a
                                  href={socialMediaLinks.twitter}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {socialMediaLinks.twitter}
                                </a>
                              ) : (
                                "N/A"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <th className="bg-light">Facebook</th>
                            <td>
                              {socialMediaLinks && socialMediaLinks.facebook ? (
                                <a
                                  href={socialMediaLinks.facebook}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {socialMediaLinks.facebook}
                                </a>
                              ) : (
                                "N/A"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <th className="bg-light">Bio</th>
                            <td>{bio}</td>
                          </tr>
                          <tr>
                            <th className="bg-light">Status</th>
                            <td>{status}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="card-footer bg-light">
                  <button
                    onClick={() => navigate("/team-members/view")}
                    className="btn btn-secondary"
                  >
                    Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default TeamMemberDetails;
