import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createTeamMember } from "../../../redux/actions/teamMember";
import ContentHeader from "../../ContentHeader";
import { useNavigate } from "react-router-dom";

function AddTeamMember() {
  const [formData, setFormData] = useState({
    name: "",
    position: "",
    teamMemberImage: null,
    bio: "",
    socialMediaLinks: {
      linkedin: "",
      twitter: "",
      facebook: "",
    },
    boardType: "Management",
    status: "approve",
  });

  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();
  const { error, loading } = useSelector((state) => state.teamMember);

  const {
    name,
    position,
    teamMemberImage,
    bio,
    socialMediaLinks,
    boardType,
    status,
  } = formData;

  const onChange = (e) => {
    const { name, value } = e.target;
    if (e.target.name === "teamMemberImage") {
      setFormData({ ...formData, [e.target.name]: e.target.files[0] });
    } else if (name.startsWith("socialMediaLinks.")) {
      const key = name.split(".")[1];
      setFormData({
        ...formData,
        socialMediaLinks: { ...socialMediaLinks, [key]: value },
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("name", name);
      formDataToSend.append("position", position);
      formDataToSend.append("bio", bio);
      formDataToSend.append("boardType", boardType);
      formDataToSend.append("status", status);
      if (teamMemberImage) {
        formDataToSend.append("teamMemberImage", teamMemberImage);
      }
      formDataToSend.append(
        "socialMediaLinks",
        JSON.stringify(socialMediaLinks)
      );

      await dispatch(createTeamMember(formDataToSend));
    } catch (err) {
      console.error("Create Team Member Error:", err);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border text-dark" role="status"></div>
      </div>
    );
  }

  return (
    <div className="content-wrapper bg-light d-flex ">
      <div className="container py-5">
        <ContentHeader title="Add Team Member" link="/team-members/view" />
        <section className="content">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card card-dark">
                <div className="card-header">
                  <h3 className="card-title mb-0">New Team Member</h3>
                </div>
                <form onSubmit={onSubmit}>
                  <div className="card-body">
                    <div className="form-row">
                      <div className="form-group col-lg-6">
                        <label htmlFor="name" className="form-label">
                          Name
                        </label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          value={name}
                          onChange={onChange}
                          className="form-control"
                          required
                        />
                      </div>
                      <div className="form-group col-lg-6">
                        <label htmlFor="position" className="form-label">
                          Position
                        </label>
                        <input
                          type="text"
                          name="position"
                          id="position"
                          value={position}
                          onChange={onChange}
                          className="form-control"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-lg-6">
                        <label htmlFor="teamMemberImage" className="form-label">
                          Team Member Image
                        </label>
                        <input
                          type="file"
                          name="teamMemberImage"
                          id="teamMemberImage"
                          // value={teamMemberImage}
                          onChange={onChange}
                          className="form-control"
                          accept="image/*"
                          required
                        />
                      </div>
                      <div className="form-group col-lg-6">
                        <label htmlFor="boardType" className="form-label">
                          Board Type
                        </label>
                        <select
                          name="boardType"
                          id="boardType"
                          value={boardType}
                          onChange={onChange}
                          className="form-control"
                          required
                        >
                          <option value="Management">Management</option>
                          <option value="Directors">Directors</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="bio" className="form-label">
                        Bio
                      </label>
                      <textarea
                        name="bio"
                        id="bio"
                        value={bio}
                        onChange={onChange}
                        className="form-control"
                        rows="6"
                      />
                    </div>
                    <div className="form-row">
                      <div className="form-group col-lg-4">
                        <label
                          htmlFor="socialMediaLinks.linkedin"
                          className="form-label"
                        >
                          LinkedIn
                        </label>
                        <input
                          type="text"
                          name="socialMediaLinks.linkedin"
                          id="linkedin"
                          value={socialMediaLinks.linkedin}
                          onChange={onChange}
                          className="form-control"
                          placeholder="Linkedin"
                        />
                      </div>
                      <div className="form-group col-lg-4">
                        <label
                          htmlFor="socialMediaLinks.twitter"
                          className="form-label"
                        >
                          Twitter
                        </label>
                        <input
                          type="text"
                          name="socialMediaLinks.twitter"
                          id="twitter"
                          value={socialMediaLinks.twitter}
                          onChange={onChange}
                          className="form-control"
                          placeholder="Twitter(X)"
                        />
                      </div>
                      <div className="form-group col-lg-4">
                        <label
                          htmlFor="socialMediaLinks.facebook"
                          className="form-label"
                        >
                          Facebook
                        </label>
                        <input
                          type="text"
                          name="socialMediaLinks.facebook"
                          id="facebook"
                          value={socialMediaLinks.facebook}
                          onChange={onChange}
                          className="form-control"
                          placeholder="Facebook"
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="status" className="form-label">
                        Status
                      </label>
                      <select
                        name="status"
                        id="status"
                        value={status}
                        onChange={onChange}
                        className="form-control"
                      >
                        <option value="approve">Approve</option>
                        <option value="pending">Pending</option>
                      </select>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="d-flex justify-content-between">
                      <button
                        onClick={() => navigate(-1)}
                        className="btn btn-secondary btn-md"
                      >
                        <i className="fas fa-times mr-2"></i>Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-success btn-md"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm btn-md"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Adding...
                          </>
                        ) : (
                          <>
                            <i className="fas fa-save mr-1"></i> Save Team
                            Member
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
export default AddTeamMember;
