import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  teamMembers: [],
  teamMember: null,
  loading: true,
  error: null,
};

const teamMemberSlice = createSlice({
  name: "teamMember",
  initialState,
  reducers: {
    getTeamMembersSuccess: (state, action) => {
      state.teamMembers = action.payload;
      state.loading = false;
    },
    getTeamMemberSuccess: (state, action) => {
      state.teamMember = action.payload;
      state.loading = false;
    },
    createTeamMemberSuccess: (state, action) => {
      state.teamMembers.push(action.payload);
      state.loading = false;
    },
    updateTeamMemberSuccess: (state, action) => {
      const index = state.teamMembers.findIndex(
        (teamMember) => teamMember._id === action.payload._id
      );
      if (index !== -1) {
        state.teamMembers[index] = action.payload;
      }
      state.loading = false;
    },
    deleteTeamMemberSuccess: (state, action) => {
      state.teamMembers = state.teamMembers.filter(
        (teamMember) => teamMember._id !== action.payload
      );
      state.loading = false;
    },
    teamMemberError: (state, action) => {
      state.loading = false;
      state.error = action.payload || "An error occurred";
    },
  },
});

export const {
  getTeamMembersSuccess,
  getTeamMemberSuccess,
  createTeamMemberSuccess,
  updateTeamMemberSuccess,
  deleteTeamMemberSuccess,
  teamMemberError,
} = teamMemberSlice.actions;
export default teamMemberSlice.reducer;
