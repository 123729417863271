import axios from "axios";
import {
  getTariffSuccess,
  getTariffsSuccess,
  createTariffSuccess,
  updateTariffSuccess,
  deleteTariffSuccess,
  tariffError,
} from "../reducers/tariffReducer";

const API_URL = process.env.REACT_APP_API_URL;

const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  return token ? { Authorization: `Bearer ${token}` } : {};
};

export const getTariffs = () => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}/tariffs`, {
      headers: getAuthHeader(),
    });
    dispatch(getTariffsSuccess(res.data.data));
  } catch (err) {
    dispatch(
      tariffError(err.response?.data?.error || "Failed to fetch tariffs")
    );
  }
};

export const getTariff = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}/tariffs/${id}`, {
      headers: getAuthHeader(),
    });
    dispatch(getTariffSuccess(res.data.data));
  } catch (err) {
    dispatch(
      tariffError(err.response?.data?.error || "Failed to fetch tariff")
    );
  }
};

export const createTariff = (tariffData) => async (dispatch) => {
  try {
    console.log("Tariff Data:", tariffData);

    const res = await axios.post(`${API_URL}/tariffs`, tariffData, {
      headers: {
        ...getAuthHeader(),
        "Content-Type": "application/json",
      },
    });

    if (res.data.success) {
      dispatch(createTariffSuccess(res.data.data));
      // Redirect after successful creation
      window.location.href = "/tariffs/view";
    } else {
      throw new Error(res.data.error || "Failed to create tariff");
    }
  } catch (err) {
    dispatch(
      tariffError(
        err.response?.data?.error ||
          err.message ||
          "An error occurred while creating the tariff"
      )
    );
  }
};

export const updateTariff = (id, tariffData) => async (dispatch) => {
  try {
    const res = await axios.put(`${API_URL}/tariffs/${id}`, tariffData, {
      headers: getAuthHeader(),
    });

    if (res.data.success) {
      dispatch(updateTariffSuccess(res.data.data));
      window.location.href = "/tariffs/view";
    } else {
      throw new Error(res.data.error || "Failed to update tariff");
    }
  } catch (err) {
    dispatch(tariffError());
  }
};

export const deleteTariff = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(`${API_URL}/tariffs/${id}`, {
      headers: getAuthHeader(),
    });
    if (res.data.success) {
      dispatch(deleteTariffSuccess(id));
    } else {
      throw new Error(res.data.error || "Failed to delete tariff");
    }
  } catch (err) {
    dispatch(tariffError());
  }
};
