import axios from "axios";
import {
  userLoaded,
  loginSuccess,
  authError,
  logout,
} from "../reducers/authReducer";

const API_URL = process.env.REACT_APP_API_URL;

export const loadUser = () => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (!token) {
    dispatch(authError());
    return;
  }

  try {
    const res = await axios.get(`${API_URL}/auth/me`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(userLoaded(res.data.data));
  } catch (err) {
    if (err.response && err.response.status === 401) {
      // Token has expired
      dispatch(authError());
      localStorage.removeItem("token"); // Clear the expired token
      // Redirect to login page
      window.location.href = "/login";
    } else {
      dispatch(authError());
    }
  }
};

export const login = (email, password) => async (dispatch) => {
  try {
    const res = await axios.post(`${API_URL}/auth/login`, { email, password });
    localStorage.setItem("token", res.data.token);
    dispatch(loginSuccess(res.data));
    await dispatch(loadUser());
    return { success: true };
  } catch (err) {
    const errorMessage =
      err.response && err.response.data && err.response.data.error
        ? err.response.data.error
        : "Login failed. Please check your credentials.";
    dispatch(authError(errorMessage));
    return { success: false, error: errorMessage };
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("token");
    await axios.get(`${API_URL}/auth/logout`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    localStorage.removeItem("token");
    dispatch(logout());
  } catch (err) {
    console.error(
      "Logout error:",
      err.response ? err.response.data : err.message
    );
    dispatch(authError());
  }
};
