import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { getService } from "../../../redux/actions/service";
import ContentHeader from "../../ContentHeader";

function ServiceDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { service, loading, error } = useSelector((state) => state.service);

  useEffect(() => {
    dispatch(getService(id));
  }, [dispatch, id]);

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border text-dark" role="status"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="alert alert-danger" role="alert">
        Error loading Service details: {error}
      </div>
    );
  }

  if (!service) {
    return (
      <div className="alert alert-info" role="alert">
        No service data available.
      </div>
    );
  }

  const { bankingType, title, serviceImage, tabs, status } = service;

  return (
    <div className="content-wrapper bg-light">
      <ContentHeader title="Service Details" link="/services/view" />
      <section className="content py-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card shadow">
                <div className="card-header bg-dark text-white">
                  <h3 className="card-title mb-0">{title}</h3>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4">
                      <img
                        src={`${serviceImage}`}
                        alt="Service"
                        className="img-fluid rounded"
                      />
                    </div>
                    <div className="col-md-8">
                      <div
                        className="tariff-details"
                        style={{
                          backgroundColor: "#f8f9fa",
                          border: "1px solid #dee2e6",
                          borderRadius: "4px",
                          padding: "20px",
                          marginBottom: "20px",
                        }}
                      >
                        <h4 style={{ marginBottom: "15px", color: "#343a40" }}>
                          Tariff Details
                        </h4>
                        <div style={{ display: "flex", marginBottom: "10px" }}>
                          <span
                            style={{
                              fontWeight: "bold",
                              width: "120px",
                              color: "#495057",
                            }}
                          >
                            Title:
                          </span>
                          <span>{title}</span>
                        </div>
                        <div style={{ display: "flex", marginBottom: "10px" }}>
                          <span
                            style={{
                              fontWeight: "bold",
                              width: "120px",
                              color: "#495057",
                            }}
                          >
                            Banking Type:
                          </span>
                          <span>{bankingType}</span>
                        </div>
                        <div style={{ display: "flex", marginBottom: "10px" }}>
                          <span
                            style={{
                              fontWeight: "bold",
                              width: "120px",
                              color: "#495057",
                            }}
                          >
                            Status:
                          </span>
                          <span
                            style={{
                              color:
                                status === "approve" ? "#28a745" : "#ffc107",
                              fontWeight: "bold",
                            }}
                          >
                            {status}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-12">
                      <h4>Tabs</h4>
                      {tabs.map((tab, index) => (
                        <div
                          key={index}
                          className="card mb-3 border-0 shadow-sm"
                        >
                          <div className="card-header bg-light">
                            <h5>{tab.content.title}</h5>
                          </div>
                          <div className="card-body">
                            <p>{tab.content.description}</p>
                            <h6>{tab.content.item1Title}</h6>
                            <ul>
                              {tab.content.items1.map((item, i) => (
                                <li key={i}>{item}</li>
                              ))}
                            </ul>
                            <h6>{tab.content.item2Title}</h6>
                            <ul>
                              {tab.content.items2.map((item, i) => (
                                <li key={i}>{item}</li>
                              ))}
                            </ul>
                            <p>
                              <strong>Page Link:</strong> {tab.content.pageLink}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="card-footer bg-light">
                  <button
                    onClick={() => navigate("/services/view")}
                    className="btn btn-secondary"
                  >
                    <i className="fas fa-times mr-1"></i> Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ServiceDetails;
