import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createUser } from "../../../redux/actions/user";
import ContentHeader from "../../ContentHeader";
import { useNavigate } from "react-router-dom";

const AddUser = () => {
  const [formData, setFormData] = useState({
    userName: "",
    email: "",
    dob: "",
    password: "",
    firstName: "",
    lastName: "",
    role: "user",
    status: "active",
    phone: "",
    address: "",
    profilePicture: null,
  });

  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [showRoleDropdown, setShowRoleDropdown] = useState(false);
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);

  const dispatch = useDispatch();
  const { error, loading } = useSelector((state) => state.user);

  const {
    userName,
    email,
    dob,
    password,
    firstName,
    lastName,
    role,
    status,
    phoneNumber,
    address,
    profilePicture,
  } = formData;

  const roles = [
    { id: "user", name: "User" },
    { id: "admin", name: "Admin" },
  ];

  const statuses = [
    { id: "active", name: "Active" },
    { id: "inactive", name: "Inactive" },
  ];

  const onChange = (e) => {
    if (e.target.name === "profilePicture") {
      setFormData({ ...formData, [e.target.name]: e.target.files[0] });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleRoleChange = (roleId) => {
    setFormData({ ...formData, role: roleId });
    setShowRoleDropdown(false);
  };

  const handleStatusChange = (statusId) => {
    setFormData({ ...formData, status: statusId });
    setShowStatusDropdown(false);
  };

  const toggleRoleDropdown = () => {
    setShowRoleDropdown(!showRoleDropdown);
  };

  const toggleStatusDropdown = () => {
    setShowStatusDropdown(!showStatusDropdown);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("userName", formData.userName);
      formDataToSend.append("email", formData.email);
      formDataToSend.append("dob", formData.dob);
      formDataToSend.append("password", formData.password);
      formDataToSend.append("firstName", formData.firstName);
      formDataToSend.append("lastName", formData.lastName);
      formDataToSend.append("role", formData.role);
      formDataToSend.append("status", formData.status);
      formDataToSend.append("phoneNumber", formData.phoneNumber);
      formDataToSend.append("address", formData.address);
      if (formData.profilePicture) {
        formDataToSend.append("profilePicture", formData.profilePicture);
      }

      await dispatch(createUser(formDataToSend));
    } catch (err) {
      console.error("Create User Error:", err);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border text-dark" role="status"></div>
      </div>
    );
  }

  return (
    <div className="content-wrapper bg-light">
      <div className="container mb-5">
        <ContentHeader title="User" link="/users/view" />
        <section className="content">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card card-dark">
                <div className="card-header text-white">
                  <h3 className="card-title mb-0">User Information</h3>
                </div>
                <form onSubmit={onSubmit}>
                  <div className="card-body">
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="userName" className="form-label">
                          User Name
                        </label>
                        <input
                          type="text"
                          name="userName"
                          id="userName"
                          value={userName}
                          onChange={onChange}
                          className="form-control"
                          required
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="email" className="form-label">
                          Email
                        </label>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          value={email}
                          onChange={onChange}
                          className="form-control"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="dob" className="form-label">
                          Date of Birth
                        </label>
                        <input
                          type="date"
                          name="dob"
                          id="dob"
                          value={dob}
                          onChange={onChange}
                          className="form-control"
                          required
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="password" className="form-label">
                          Password
                        </label>
                        <input
                          type="password"
                          name="password"
                          id="password"
                          value={password}
                          onChange={onChange}
                          className="form-control"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="firstName" className="form-label">
                          First Name
                        </label>
                        <input
                          type="text"
                          name="firstName"
                          id="firstName"
                          value={firstName}
                          onChange={onChange}
                          className="form-control"
                          required
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="lastName" className="form-label">
                          Last Name
                        </label>
                        <input
                          type="text"
                          name="lastName"
                          id="lastName"
                          value={lastName}
                          onChange={onChange}
                          className="form-control"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="role" className="form-label">
                          Role
                        </label>
                        <div className="dropdown">
                          <button
                            type="button"
                            className="btn btn-light w-100 d-flex justify-content-between align-items-center text-dark"
                            onClick={toggleRoleDropdown}
                            style={{
                              backgroundColor: "white",
                              border: "1px solid #ced4da",
                              transition: "background-color 0.3s",
                            }}
                          >
                            <span>
                              {role
                                ? roles.find((r) => r.id === role).name
                                : "Select Role"}
                            </span>
                            <i className="fa fa-caret-down"></i>
                          </button>
                          {showRoleDropdown && (
                            <div className="dropdown-menu w-100 show">
                              <div
                                className="dropdown-menu-inner"
                                style={{
                                  maxHeight: "200px",
                                  overflowY: "auto",
                                }}
                              >
                                {roles.map((r) => (
                                  <div
                                    key={r.id}
                                    className="dropdown-item"
                                    onClick={() => handleRoleChange(r.id)}
                                    style={{
                                      color: "black",
                                      backgroundColor: "white",
                                      transition: "background-color 0.3s",
                                    }}
                                    onMouseEnter={(e) =>
                                      (e.target.style.backgroundColor =
                                        "#f8f9fa")
                                    }
                                    onMouseLeave={(e) =>
                                      (e.target.style.backgroundColor = "white")
                                    }
                                  >
                                    {r.name}
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="status">Status</label>
                        <div className="dropdown">
                          <button
                            type="button"
                            className="btn btn-light w-100 d-flex justify-content-between align-items-center text-dark"
                            onClick={toggleStatusDropdown}
                            style={{
                              backgroundColor: "white",
                              border: "1px solid #ced4da",
                              transition: "background-color 0.3s",
                            }}
                          >
                            <span>
                              {status
                                ? statuses.find((s) => s.id === status).name
                                : "Select Status"}
                            </span>
                            <i className="fa fa-caret-down"></i>
                          </button>
                          {showStatusDropdown && (
                            <div className="dropdown-menu w-100 show">
                              <div
                                className="dropdown-menu-inner"
                                style={{
                                  maxHeight: "200px",
                                  overflowY: "auto",
                                }}
                              >
                                {statuses.map((s) => (
                                  <div
                                    key={s.id}
                                    className="dropdown-item"
                                    onClick={() => handleStatusChange(s.id)}
                                    style={{
                                      color: "black",
                                      backgroundColor: "white",
                                      transition: "background-color 0.3s",
                                    }}
                                    onMouseEnter={(e) =>
                                      (e.target.style.backgroundColor =
                                        "#f8f9fa")
                                    }
                                    onMouseLeave={(e) =>
                                      (e.target.style.backgroundColor = "white")
                                    }
                                  >
                                    {s.name}
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="phoneNumber" className="form-label">
                          Phone Number
                        </label>
                        <input
                          type="text"
                          name="phoneNumber"
                          id="phoneNumber"
                          value={phoneNumber}
                          onChange={onChange}
                          className="form-control"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="address" className="form-label">
                          Address
                        </label>
                        <input
                          type="text"
                          name="address"
                          id="address"
                          value={address}
                          onChange={onChange}
                          className="form-control"
                        />
                      </div>

                      <div className="form-group col-md-12">
                        <label htmlFor="profilePicture" className="form-label">
                          Profile Picture
                        </label>
                        <input
                          type="file"
                          name="profilePicture"
                          id="profilePicture"
                          onChange={onChange}
                          className="form-control"
                          accept="image/*"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="d-flex justify-content-between">
                      <button
                        onClick={() => navigate(-1)}
                        className="btn btn-secondary btn-md"
                      >
                        <i className="fas fa-times mr-2"></i>Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-success btn-md"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Adding...
                          </>
                        ) : (
                          <>
                            <i className="fas fa-save mr-1"></i> Save User
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AddUser;
