const InfoBox = ({ bgClass, iconClass, number, text }) => {
  return (
    <div className="col-lg-3 col-6">
      <div className={`small-box ${bgClass}`}>
        <div className="inner">
          <h3>{number}</h3>
          <p>{text}</p>
        </div>

        <span className="small-box-footer" />
      </div>
    </div>
  );
};

export default InfoBox;
