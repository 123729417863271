import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createTariff } from "../../../redux/actions/tariff";
import ContentHeader from "../../ContentHeader";

function AddTariff() {
  const [tariffData, setTariffData] = useState({
    tableTitle: "",
    effectiveDate: "",
    sections: [
      {
        title: "",
        headers: ["Item", "Liberian Dollars", "US Dollars"],
        rows: [
          { items: [{ item: "" }, { liberiandollars: "" }, { usdollars: "" }] },
        ],
      },
    ],
    status: "pending",
  });

  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.tariff);

  const handleChange = (e) => {
    setTariffData({ ...tariffData, [e.target.name]: e.target.value });
  };

  const handleSectionChange = (index, field, value) => {
    const newSections = [...tariffData.sections];
    newSections[index][field] = value;
    setTariffData({ ...tariffData, sections: newSections });
  };

  const handleHeaderChange = (sectionIndex, headerIndex, value) => {
    const newSections = [...tariffData.sections];
    newSections[sectionIndex].headers[headerIndex] = value;
    // Update the corresponding item keys in each row
    const newKey = value.toLowerCase().replace(/\s+/g, "");
    newSections[sectionIndex].rows.forEach((row) => {
      const oldKey = Object.keys(row.items[headerIndex])[0];
      row.items[headerIndex] = {
        [newKey]: row.items[headerIndex][oldKey] || "",
      };
    });
    setTariffData({ ...tariffData, sections: newSections });
  };

  const addHeader = (sectionIndex) => {
    const newSections = [...tariffData.sections];
    const newHeader = `New Column ${
      newSections[sectionIndex].headers.length + 1
    }`;
    newSections[sectionIndex].headers.push(newHeader);
    const newKey = newHeader.toLowerCase().replace(/\s+/g, "");
    newSections[sectionIndex].rows.forEach((row) => {
      row.items.push({ [newKey]: "" });
    });
    setTariffData({ ...tariffData, sections: newSections });
  };

  const removeHeader = (sectionIndex, headerIndex) => {
    const newSections = [...tariffData.sections];
    newSections[sectionIndex].headers.splice(headerIndex, 1);
    newSections[sectionIndex].rows.forEach((row) => {
      row.items.splice(headerIndex, 1);
    });
    setTariffData({ ...tariffData, sections: newSections });
  };

  const handleRowChange = (sectionIndex, rowIndex, itemIndex, value) => {
    const newSections = [...tariffData.sections];
    const key = Object.keys(
      newSections[sectionIndex].rows[rowIndex].items[itemIndex]
    )[0];
    newSections[sectionIndex].rows[rowIndex].items[itemIndex] = {
      [key]: value,
    };
    setTariffData({ ...tariffData, sections: newSections });
  };

  const addSection = () => {
    setTariffData({
      ...tariffData,
      sections: [
        ...tariffData.sections,
        {
          title: "",
          headers: ["Item", "Liberian Dollars", "US Dollars"],
          rows: [
            {
              items: [{ item: "" }, { liberiandollars: "" }, { usdollars: "" }],
            },
          ],
        },
      ],
    });
  };

  const removeSection = (sectionIndex) => {
    const newSections = [...tariffData.sections];
    newSections.splice(sectionIndex, 1);
    setTariffData({ ...tariffData, sections: newSections });
  };

  const addRow = (sectionIndex) => {
    const newSections = [...tariffData.sections];
    const newRow = {
      items: newSections[sectionIndex].headers.map((header) => ({
        [header.toLowerCase().replace(/\s+/g, "")]: "",
      })),
    };
    newSections[sectionIndex].rows.push(newRow);
    setTariffData({ ...tariffData, sections: newSections });
  };

  const removeRow = (sectionIndex, rowIndex) => {
    const newSections = [...tariffData.sections];
    newSections[sectionIndex].rows.splice(rowIndex, 1);
    setTariffData({ ...tariffData, sections: newSections });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(createTariff(tariffData));
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border text-dark" role="status"></div>
      </div>
    );
  }

  return (
    <div className="content-wrapper bg-light">
      <div className="container py-5">
        <ContentHeader title="Add Tariff" link="/tariffs/view" />
        <section className="content">
          <div className="card shadow">
            <div className="card-header bg-dark text-white">
              <h3 className="card-title mb-0">New Tariff</h3>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-md-12">
                    <label htmlFor="tableTitle" className="form-label">
                      Table Title
                    </label>
                    <input
                      type="text"
                      id="tableTitle"
                      name="tableTitle"
                      className="form-control"
                      value={tariffData.tableTitle}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label htmlFor="effectiveDate" className="form-label">
                      Effective Date
                    </label>
                    <input
                      type="date"
                      id="effectiveDate"
                      name="effectiveDate"
                      className="form-control"
                      value={tariffData.effectiveDate}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="status" className="form-label">
                      Status
                    </label>
                    <select
                      id="status"
                      name="status"
                      className="form-control"
                      value={tariffData.status}
                      onChange={handleChange}
                      required
                    >
                      <option value="pending">Pending</option>
                      <option value="approve">Approve</option>
                    </select>
                  </div>
                </div>
                {tariffData.sections.map((section, sectionIndex) => (
                  <div key={sectionIndex} className="card mb-4">
                    <div className="card-body">
                      <div className="mb-3 d-flex justify-content-between align-items-center">
                        <input
                          type="text"
                          className="form-control w-75"
                          value={section.title}
                          onChange={(e) =>
                            handleSectionChange(
                              sectionIndex,
                              "title",
                              e.target.value
                            )
                          }
                          placeholder="Section Title"
                          required
                        />
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => removeSection(sectionIndex)}
                        >
                          Remove Section
                        </button>
                      </div>
                      <div className="mb-3">
                        {section.headers.map((header, headerIndex) => (
                          <div key={headerIndex} className="d-flex mb-2">
                            <input
                              type="text"
                              className="form-control"
                              value={header}
                              onChange={(e) =>
                                handleHeaderChange(
                                  sectionIndex,
                                  headerIndex,
                                  e.target.value
                                )
                              }
                              required
                            />
                            {headerIndex > 0 && (
                              <button
                                type="button"
                                className="btn btn-outline-danger ms-2"
                                onClick={() =>
                                  removeHeader(sectionIndex, headerIndex)
                                }
                              >
                                Remove
                              </button>
                            )}
                          </div>
                        ))}
                        <button
                          type="button"
                          className="btn btn-outline-primary mt-2"
                          onClick={() => addHeader(sectionIndex)}
                        >
                          Add Header
                        </button>
                      </div>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            {section.headers.map((header, index) => (
                              <th key={index}>{header}</th>
                            ))}
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {section.rows.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                              {row.items.map((item, itemIndex) => (
                                <td key={itemIndex}>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={Object.values(item)[0]}
                                    onChange={(e) =>
                                      handleRowChange(
                                        sectionIndex,
                                        rowIndex,
                                        itemIndex,
                                        e.target.value
                                      )
                                    }
                                    required
                                  />
                                </td>
                              ))}
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-danger btn-sm"
                                  onClick={() =>
                                    removeRow(sectionIndex, rowIndex)
                                  }
                                >
                                  Remove
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <button
                        type="button"
                        className="btn btn-secondary mt-2"
                        onClick={() => addRow(sectionIndex)}
                      >
                        Add Row
                      </button>
                    </div>
                  </div>
                ))}
                <button
                  type="button"
                  className="btn btn-primary mb-3"
                  onClick={addSection}
                >
                  Add Section
                </button>
              </div>
              <div className="card-footer">
                <button type="submit" className="btn btn-success">
                  Save Tariff
                </button>
              </div>
            </form>
          </div>
        </section>
      </div>
    </div>
  );
}

export default AddTariff;
