import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/authReducer";
import userReducer from "./reducers/userReducer";
import bannerReducer from "./reducers/bannerReducer";
import teamMemberReducer from "./reducers/teamMemberReducer";
import faqReducer from "./reducers/faqReducer";
import tariffReducer from "./reducers/tariffReducer";
import serviceReducer from "./reducers/serviceReducer";
import testimonialReducer from "./reducers/testimonialReducer";
import blogReducer from "./reducers/blogReducer";
import jobReducer from "./reducers/jobReducer";
import reportReducer from "./reducers/reportReducer";
import contactReducer from "./reducers/contactSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    banner: bannerReducer,
    teamMember: teamMemberReducer,
    faq: faqReducer,
    tariff: tariffReducer,
    service: serviceReducer,
    testimonial: testimonialReducer,
    blog: blogReducer,
    job: jobReducer,
    report: reportReducer,
    contact: contactReducer,
  },
});

export default store;
