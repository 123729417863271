import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { getFaq } from "../../../redux/actions/faq";
import ContentHeader from "../../ContentHeader";

function FaqsDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { faq, loading, error } = useSelector((state) => state.faq);

  useEffect(() => {
    dispatch(getFaq(id));
  }, [dispatch, id]);

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border text-dark" role="status"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="alert alert-danger" role="alert">
        Error loading FAQ details: {error}
      </div>
    );
  }

  if (!faq) {
    return (
      <div className="alert alert-warning" role="alert">
        FAQ not found
      </div>
    );
  }

  const { question, answer, status } = faq;

  return (
    <div className="content-wrapper bg-light py-5">
      <ContentHeader title="FAQ Details" link="/faqs/view" />
      <section className="content">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card card-dark">
                <div className="card-header bg-dark text-white">
                  <h3 className="card-title mb-0">{question}</h3>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <table className="table table-bordered table-hover">
                        <tbody>
                          <tr>
                            <th className="bg-light" style={{ width: "20%" }}>
                              Question
                            </th>
                            <td>{question}</td>
                          </tr>
                          <tr>
                            <th className="bg-light">Answer</th>
                            <td>{answer}</td>
                          </tr>
                          <tr>
                            <th className="bg-light">Status</th>
                            <td>
                              <span
                                className={`badge badge-${
                                  status === "approve" ? "success" : "warning"
                                } px-3 py-2`}
                              >
                                {status}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="card-footer bg-light">
                  <button
                    onClick={() => navigate("/faqs/view")}
                    className="btn btn-secondary"
                  >
                    <i className="fas fa-arrow-left me-2"></i> Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default FaqsDetails;
