import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getContacts, deleteContact } from "../../../redux/actions/contact";
import ContentHeader from "../../ContentHeader";
import { useNavigate } from "react-router-dom";

function ViewContacts() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const contactState = useSelector((state) => state.contact);

  // Safely access the contact state properties
  const contacts = contactState?.contacts || [];
  const loading = contactState?.loading || false;
  const error = contactState?.error || null;

  useEffect(() => {
    dispatch(getContacts());
  }, [dispatch]);

  const handleDelete = (id) => {
    if (
      window.confirm("Are you sure you want to delete this contact submission?")
    ) {
      dispatch(deleteContact(id));
    }
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border text-dark" role="status"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="alert alert-danger" role="alert">
        Error: {error}
      </div>
    );
  }

  return (
    <div className="content-wrapper bg-light">
      <ContentHeader title="View Contacts" link="/contacts/view" />
      <section className="content">
        <div className="container-fluid py-5">
          <div className="card shadow">
            <div className="card-header bg-dark text-white">
              <h3 className="card-title mb-0">Contact Submissions</h3>
            </div>
            <div className="card-body">
              {contacts.length > 0 ? (
                <div className="table-responsive">
                  <table className="table table-hover table-striped">
                    <thead className="table-light">
                      <tr>
                        <th>#</th>
                        <th>Full Name</th>
                        <th>Email</th>
                        <th>Subject</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {contacts.map((contact, index) => (
                        <tr key={contact._id}>
                          <td>{index + 1}</td>
                          <td>{contact.fullName}</td>
                          <td>{contact.email}</td>
                          <td>{contact.subject}</td>
                          <td>
                            <div className="btn-group" role="group">
                              <button
                                className="btn btn-outline-primary btn-sm mr-1"
                                onClick={() =>
                                  navigate(`/contacts/${contact._id}`)
                                }
                              >
                                <i className="fas fa-eye me-1"></i> View
                              </button>
                              <button
                                className="btn btn-outline-danger btn-sm"
                                onClick={() => handleDelete(contact._id)}
                              >
                                <i className="fas fa-trash me-1"></i> Delete
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p>No contact submissions found.</p>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ViewContacts;
