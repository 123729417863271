import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import store from "./redux/store";
import ReduxWrapper from "./ReduxWrapper";
import "../node_modules/react-modal/dist/react-modal";

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <Provider store={store}>
    <ReduxWrapper />
  </Provider>
);
