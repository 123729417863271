const Preloader = () => {
  return (
    <div className="preloader flex-column justify-content-center align-items-center">
      <img
        className="animation__wobble"
        src="/assets/img/ABL_logo_transparent.png.png"
        alt="AccessBank Logo"
        height={200}
        width={300}
      />
    </div>
  );
};

export default Preloader;
