import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reports: [],
  report: null,
  loading: true,
  error: null,
};

const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    getReportsSuccess: (state, action) => {
      state.reports = action.payload;
      state.loading = false;
    },
    getReportSuccess: (state, action) => {
      state.report = action.payload;
      state.loading = false;
    },
    createReportSuccess: (state, action) => {
      state.reports.push(action.payload);
      state.loading = false;
    },
    updateReportSuccess: (state, action) => {
      const index = state.reports.findIndex(
        (report) => report._id === action.payload._id
      );
      if (index !== -1) {
        state.reports[index] = action.payload;
      }
      state.loading = false;
    },
    deleteReportSuccess: (state, action) => {
      state.reports = state.reports.filter(
        (report) => report._id !== action.payload
      );
      state.loading = false;
    },
    reportError: (state, action) => {
      state.loading = false;
      state.error = action.payload || "An error occurred";
    },
  },
});

export const {
  getReportsSuccess,
  getReportSuccess,
  createReportSuccess,
  updateReportSuccess,
  deleteReportSuccess,
  reportError,
} = reportSlice.actions;
export default reportSlice.reducer;
