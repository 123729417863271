import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getTariff, updateTariff } from "../../../redux/actions/tariff";
import ContentHeader from "../../ContentHeader";

function EditTariff() {
  const [tariffData, setTariffData] = useState({
    tableTitle: "",
    effectiveDate: "",
    sections: [
      {
        title: "",
        headers: ["Item", "Liberian Dollars", "US Dollars"],
        rows: [
          { items: [{ item: "" }, { liberiandollars: "" }, { usdollars: "" }] },
        ],
      },
    ],
    status: "pending",
  });

  const dispatch = useDispatch();
  const { id } = useParams();
  const { tariff, loading, error } = useSelector((state) => state.tariff);

  useEffect(() => {
    dispatch(getTariff(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (tariff) {
      setTariffData({
        ...tariff,
        effectiveDate: tariff.effectiveDate
          ? new Date(tariff.effectiveDate).toISOString().split("T")[0]
          : "",
        status: tariff.status || "pending",
      });
    }
  }, [tariff]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTariffData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSectionChange = (index, field, value) => {
    setTariffData((prevState) => {
      const newSections = prevState.sections.map((section, sectionIndex) => {
        if (sectionIndex === index) {
          return { ...section, [field]: value };
        }
        return section;
      });
      return { ...prevState, sections: newSections };
    });
  };

  const handleHeaderChange = (sectionIndex, headerIndex, value) => {
    setTariffData((prevState) => {
      const newSections = prevState.sections.map((section, index) => {
        if (index === sectionIndex) {
          const newHeaders = [...section.headers];
          newHeaders[headerIndex] = value;
          const newKey = value.toLowerCase().replace(/\s+/g, "");
          const newRows = section.rows.map((row) => ({
            ...row,
            items: row.items.map((item, idx) => {
              if (idx === headerIndex) {
                const oldKey = Object.keys(item)[0];
                return { [newKey]: item[oldKey] || "" };
              }
              return item;
            }),
          }));
          return { ...section, headers: newHeaders, rows: newRows };
        }
        return section;
      });
      return { ...prevState, sections: newSections };
    });
  };

  const addHeader = (sectionIndex) => {
    setTariffData((prevState) => {
      const newSections = prevState.sections.map((section, index) => {
        if (index === sectionIndex) {
          const newHeader = `New Column ${section.headers.length + 1}`;
          const newKey = newHeader.toLowerCase().replace(/\s+/g, "");
          return {
            ...section,
            headers: [...section.headers, newHeader],
            rows: section.rows.map((row) => ({
              ...row,
              items: [...row.items, { [newKey]: "" }],
            })),
          };
        }
        return section;
      });
      return { ...prevState, sections: newSections };
    });
  };

  const removeHeader = (sectionIndex, headerIndex) => {
    setTariffData((prevState) => {
      const newSections = prevState.sections.map((section, index) => {
        if (index === sectionIndex) {
          return {
            ...section,
            headers: section.headers.filter((_, i) => i !== headerIndex),
            rows: section.rows.map((row) => ({
              ...row,
              items: row.items.filter((_, i) => i !== headerIndex),
            })),
          };
        }
        return section;
      });
      return { ...prevState, sections: newSections };
    });
  };

  const handleRowChange = (sectionIndex, rowIndex, itemIndex, value) => {
    setTariffData((prevState) => {
      const newSections = [...prevState.sections];
      const key = Object.keys(
        newSections[sectionIndex].rows[rowIndex].items[itemIndex]
      )[0];
      newSections[sectionIndex].rows[rowIndex].items[itemIndex] = {
        [key]: value,
      };
      return { ...prevState, sections: newSections };
    });
  };

  const addSection = () => {
    setTariffData((prevState) => ({
      ...prevState,
      sections: [
        ...prevState.sections,
        {
          title: "",
          headers: ["Item", "Liberian Dollars", "US Dollars"],
          rows: [
            {
              items: [{ item: "" }, { liberiandollars: "" }, { usdollars: "" }],
            },
          ],
        },
      ],
    }));
  };

  const removeSection = (sectionIndex) => {
    setTariffData((prevState) => {
      const newSections = [...prevState.sections];
      newSections.splice(sectionIndex, 1);
      return { ...prevState, sections: newSections };
    });
  };

  const addRow = (sectionIndex) => {
    setTariffData((prevState) => {
      const newSections = prevState.sections.map((section, index) => {
        if (index === sectionIndex) {
          const newRow = {
            items: section.headers.map((header) => ({
              [header.toLowerCase().replace(/\s+/g, "")]: "",
            })),
          };
          return {
            ...section,
            rows: [...section.rows, newRow],
          };
        }
        return section;
      });
      return { ...prevState, sections: newSections };
    });
  };

  const removeRow = (sectionIndex, rowIndex) => {
    setTariffData((prevState) => {
      const newSections = prevState.sections.map((section, index) => {
        if (index === sectionIndex) {
          return {
            ...section,
            rows: section.rows.filter((_, i) => i !== rowIndex),
          };
        }
        return section;
      });
      return { ...prevState, sections: newSections };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedTariffData = {
        ...tariffData,
        status: tariffData.status,
      };
      await dispatch(updateTariff(id, updatedTariffData));
    } catch (err) {
      console.error("Update Tariff Error:", err);
    }
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border text-dark" role="status"></div>
      </div>
    );
  }

  return (
    <div className="content-wrapper bg-light">
      <div className="container py-5">
        <ContentHeader title="Edit Tariff" link="/tariffs/view" />
        <section className="content">
          <div className="card shadow">
            <div className="card-header bg-dark text-white">
              <h3 className="card-title mb-0">Edit Tariff</h3>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-md-12">
                    <label htmlFor="tableTitle" className="form-label">
                      Table Title
                    </label>
                    <input
                      type="text"
                      id="tableTitle"
                      name="tableTitle"
                      className="form-control"
                      value={tariffData.tableTitle}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label htmlFor="effectiveDate" className="form-label">
                      Effective Date
                    </label>
                    <input
                      type="date"
                      id="effectiveDate"
                      name="effectiveDate"
                      className="form-control"
                      value={tariffData.effectiveDate}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="status" className="form-label">
                      Status
                    </label>
                    <select
                      id="status"
                      name="status"
                      className="form-control"
                      value={tariffData.status}
                      onChange={handleChange}
                      required
                    >
                      <option value="pending">Pending</option>
                      <option value="approve">Approve</option>
                    </select>
                  </div>
                </div>
                {tariffData.sections.map((section, sectionIndex) => (
                  <div key={sectionIndex} className="card mb-4">
                    <div className="card-body">
                      <div className="mb-3 d-flex justify-content-between align-items-center">
                        <input
                          type="text"
                          className="form-control w-75"
                          value={section.title}
                          onChange={(e) =>
                            handleSectionChange(
                              sectionIndex,
                              "title",
                              e.target.value
                            )
                          }
                          placeholder="Section Title"
                          required
                        />
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => removeSection(sectionIndex)}
                        >
                          Remove Section
                        </button>
                      </div>
                      <div className="mb-3">
                        {section.headers.map((header, headerIndex) => (
                          <div key={headerIndex} className="d-flex mb-2">
                            <input
                              type="text"
                              className="form-control"
                              value={header}
                              onChange={(e) =>
                                handleHeaderChange(
                                  sectionIndex,
                                  headerIndex,
                                  e.target.value
                                )
                              }
                              required
                            />
                            {headerIndex > 0 && (
                              <button
                                type="button"
                                className="btn btn-outline-danger ms-2"
                                onClick={() =>
                                  removeHeader(sectionIndex, headerIndex)
                                }
                              >
                                Remove
                              </button>
                            )}
                          </div>
                        ))}
                        <button
                          type="button"
                          className="btn btn-outline-primary mt-2"
                          onClick={() => addHeader(sectionIndex)}
                        >
                          Add Header
                        </button>
                      </div>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            {section.headers.map((header, index) => (
                              <th key={index}>{header}</th>
                            ))}
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {section.rows.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                              {row.items.map((item, itemIndex) => (
                                <td key={itemIndex}>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={Object.values(item)[0]}
                                    onChange={(e) =>
                                      handleRowChange(
                                        sectionIndex,
                                        rowIndex,
                                        itemIndex,
                                        e.target.value
                                      )
                                    }
                                    required
                                  />
                                </td>
                              ))}
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-danger btn-sm"
                                  onClick={() =>
                                    removeRow(sectionIndex, rowIndex)
                                  }
                                >
                                  Remove
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <button
                        type="button"
                        className="btn btn-secondary mt-2"
                        onClick={() => addRow(sectionIndex)}
                      >
                        Add Row
                      </button>
                    </div>
                  </div>
                ))}
                <button
                  type="button"
                  className="btn btn-primary mb-3"
                  onClick={addSection}
                >
                  Add Section
                </button>
              </div>
              <div className="card-footer">
                <button type="submit" className="btn btn-success">
                  Update Tariff
                </button>
              </div>
            </form>
          </div>
        </section>
      </div>
    </div>
  );
}

export default EditTariff;
