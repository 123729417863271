import axios from "axios";

import {
  getUsersSuccess,
  getUserSuccess,
  createUserSuccess,
  updateUserSuccess,
  deleteUserSuccess,
  userError,
} from "../reducers/userReducer";

const API_URL = process.env.REACT_APP_API_URL;

const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  return token ? { Authorization: `Bearer ${token}` } : {};
};

export const getUsers = () => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}/users`, {
      headers: getAuthHeader(),
    });
    dispatch(getUsersSuccess(res.data.data));
  } catch (err) {
    dispatch(userError());
  }
};

export const getUser = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}/users/${id}`, {
      headers: getAuthHeader(),
    });
    dispatch(getUserSuccess(res.data.data));
  } catch (err) {
    dispatch(userError());
  }
};

export const createUser = (userData) => async (dispatch) => {
  try {
    const res = await axios.post(`${API_URL}/users`, userData, {
      headers: {
        ...getAuthHeader(),
        "Content-Type": "multipart/form-data",
      },
    });

    if (res.data.success) {
      dispatch(createUserSuccess(res.data.data));
      // Redirect after successful creation
      window.location.href = "/users/view";
    } else {
      throw new Error(res.data.error || "Failed to create user");
    }
  } catch (err) {
    dispatch(
      userError(
        err.response?.data?.error || err.message || "An unknown error occurred"
      )
    );
  }
};

export const updateUser = (id, userData) => async (dispatch) => {
  try {
    const res = await axios.put(`${API_URL}/users/${id}`, userData, {
      headers: getAuthHeader(),
    });

    if (res.data.success) {
      dispatch(updateUserSuccess(res.data.data));
    } else {
      throw new Error(res.data.error || "Failed to update clients");
    }
  } catch (err) {
    dispatch(userError());
  }
};

export const deleteUser = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(`${API_URL}/users/${id}`, {
      headers: getAuthHeader(),
    });
    if (res.data.success) {
      dispatch(deleteUserSuccess(id));
    } else {
      throw new Error(res.data.error || "Failed to delete user");
    }
  } catch (err) {
    dispatch(userError());
  }
};
