import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createFaq } from "../../../redux/actions/faq";
import ContentHeader from "../../ContentHeader";
import { useNavigate } from "react-router-dom";

function AddFaqs() {
  const [formData, setFormData] = useState({
    question: "",
    answer: "",
    status: "approve",
  });

  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();
  const { error, loading } = useSelector((state) => state.faq);

  const { question, answer, status } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("question", question);
      formDataToSend.append("answer", answer);
      formDataToSend.append("status", status);

      await dispatch(createFaq(formDataToSend));
    } catch (err) {
      console.error("Create FAQ Error:", err);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border text-dark" role="status"></div>
      </div>
    );
  }

  return (
    <div className="content-wrapper bg-light">
      <div className="container py-5">
        <ContentHeader title="Add FAQ" link="/faqs/view" />
        <section className="content">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card shadow-sm">
                <div className="card-header bg-dark text-white">
                  <h3 className="card-title mb-0">New FAQ Entry</h3>
                </div>
                <form onSubmit={onSubmit}>
                  <div className="card-body">
                    <div className="form-row">
                      <div className="form-group col-lg-6">
                        <label htmlFor="question">Question</label>
                        <input
                          type="text"
                          name="question"
                          id="question"
                          value={question}
                          onChange={onChange}
                          className="form-control form-control-lg"
                          required
                        />
                      </div>
                      <div className="form-group col-lg-6">
                        <label htmlFor="status">Status</label>
                        <select
                          name="status"
                          id="status"
                          value={status}
                          onChange={onChange}
                          className="form-control form-control-lg"
                        >
                          <option value="approve">Approve</option>
                          <option value="pending">Pending</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="answer">Answer</label>
                      <textarea
                        name="answer"
                        id="answer"
                        value={answer}
                        onChange={onChange}
                        className="form-control form-control-lg"
                        rows="6"
                        required
                      />
                    </div>
                  </div>
                  <div className="card-footer bg-light">
                    <div className="d-flex justify-content-between">
                      <button
                        onClick={() => navigate(-1)}
                        className="btn btn-secondary btn-md"
                      >
                        <i className="fas fa-times mr-2"></i>Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-success btn-md"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Adding...
                          </>
                        ) : (
                          <>
                            <i className="fas fa-save mr-2"></i> Save FAQs
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
export default AddFaqs;
