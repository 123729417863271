import { Route, Routes, Navigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Sidebar from "../components/SideBar";
import AddTariff from "../components/custom/Tariff/AddTariff";
import ViewTariffs from "../components/custom/Tariff/ViewTariffs";
import EditTariff from "../components/custom/Tariff/EditTariff";
import TariffDetails from "../components/custom/Tariff/TariffDetails";

function Tariff() {
  return (
    <div>
      <Navbar />
      <Sidebar />
      <Routes>
        <Route path="/add" element={<AddTariff />} />
        <Route path="/view" element={<ViewTariffs />} />
        <Route path="/edit/:id" element={<EditTariff />} />
        <Route path="/:id" element={<TariffDetails />} />
        <Route path="/*" element={<Navigate to="/view" replace />} />
      </Routes>
    </div>
  );
}
export default Tariff;
