import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTariffs, deleteTariff } from "../../../redux/actions/tariff";
import ContentHeader from "../../ContentHeader";
import { useNavigate } from "react-router-dom";

function ViewTariff() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { tariffs, loading } = useSelector((state) => state.tariff);

  useEffect(() => {
    dispatch(getTariffs());
  }, [dispatch]);

  const handleEdit = (id) => {
    navigate(`/tariffs/edit/${id}`);
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this tariff?")) {
      dispatch(deleteTariff(id));
    }
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border text-dark" role="status"></div>
      </div>
    );
  }

  return (
    <div className="content-wrapper bg-light">
      <ContentHeader title="View Tariffs" link="/tariffs/view" />
      <section className="content">
        <div className="container-fluid py-5">
          <div className="card shadow">
            <div className="card-header bg-dark text-white">
              <h3 className="card-title mb-0">Tariff List</h3>
            </div>
            <div className="card-body p-0">
              <div className="table-responsive">
                <table className="table table-hover table-striped mb-0">
                  <thead className="table-light">
                    <tr>
                      <th>#</th>
                      <th>Title</th>
                      <th>Effective Date</th>
                      <th>Status</th>
                      <th className="text-end">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tariffs.map((tariff, index) => (
                      <tr key={tariff._id}>
                        <td>{index + 1}</td>
                        <td>{tariff.tableTitle}</td>
                        <td>
                          {new Date(tariff.effectiveDate).toLocaleDateString()}
                        </td>
                        <td>
                          <span
                            className={`badge ${
                              tariff.status === "approve"
                                ? "bg-success"
                                : "bg-warning"
                            } px-3 py-2`}
                          >
                            {tariff.status}
                          </span>
                        </td>
                        <td className="text-end">
                          <button
                            className="btn btn-outline-primary btn-sm mr-1"
                            onClick={() => navigate(`/tariffs/${tariff._id}`)}
                          >
                            <i className="fas fa-eye"></i> View
                          </button>
                          <button
                            className="btn btn-outline-secondary btn-sm mr-1"
                            onClick={() => handleEdit(tariff._id)}
                          >
                            <i className="fas fa-edit"></i> Edit
                          </button>
                          <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={() => handleDelete(tariff._id)}
                          >
                            <i className="fas fa-trash"></i> Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ViewTariff;
