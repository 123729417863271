import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { getBanner } from "../../../redux/actions/banner";
import ContentHeader from "../../ContentHeader";

function BannerDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { banner, loading, error } = useSelector((state) => state.banner);

  useEffect(() => {
    dispatch(getBanner(id));
  }, [dispatch, id]);

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border text-dark" role="status"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="alert alert-danger" role="alert">
        Error loading banner details: {error}
      </div>
    );
  }

  if (!banner) {
    return (
      <div className="alert alert-info" role="alert">
        No banner details available.
      </div>
    );
  }

  const { title, subtitle, bannerImage, status } = banner;

  return (
    <div className="content-wrapper bg-light py-5">
      <ContentHeader title="Banner Details" link="/banners/view" />
      <section className="content">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card card-dark">
                <div className="card-header bg-dark text-white">
                  <h3 className="card-title mb-0">{title}</h3>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <img
                        src={`${bannerImage}`}
                        alt="Banner"
                        className="img-fluid rounded"
                      />
                    </div>
                    <div className="col-md-6">
                      <h5 className="mb-3">Banner Information</h5>
                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <th scope="row" className="text-muted">
                              Title
                            </th>
                            <td>{title}</td>
                          </tr>
                          <tr>
                            <th scope="row" className="text-muted">
                              Subtitle
                            </th>
                            <td>{subtitle}</td>
                          </tr>

                          <tr>
                            <th
                              className={`mt-1 ml-2 badge ${
                                status === "approve"
                                  ? "bg-success"
                                  : "bg-warning"
                              }`}
                            >
                              Status
                            </th>
                            <td>{status}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="card-footer bg-light">
                  <button
                    onClick={() => navigate("/banners/view")}
                    className="btn btn-secondary"
                  >
                    <i className="fas fa-arrow-left me-2"></i> Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default BannerDetails;
