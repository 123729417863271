import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getUser, updateUser } from "../../../redux/actions/user";
import ContentHeader from "../../ContentHeader";

const EditUser = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, loading, error } = useSelector((state) => state.user);
  const [formData, setFormData] = useState({
    userName: "",
    email: "",
    dob: "",
    password: "",
    firstName: "",
    lastName: "",
    role: "user",
    status: "active",
    phoneNumber: "",
    address: "",
    profilePicture: null,
  });

  const [showRoleDropdown, setShowRoleDropdown] = useState(false);
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);

  const roles = [
    { id: "user", name: "User" },
    { id: "admin", name: "Admin" },
  ];

  const statuses = [
    { id: "active", name: "Active" },
    { id: "inactive", name: "Inactive" },
  ];

  useEffect(() => {
    dispatch(getUser(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (user) {
      const formattedDate = new Date(user.dob).toISOString().split("T")[0];

      setFormData({
        userName: user.userName,
        email: user.email,
        dob: formattedDate,
        password: "",
        firstName: user.firstName,
        lastName: user.lastName,
        role: user.role,
        status: user.status,
        phoneNumber: user.phoneNumber,
        address: user.address,
        profilePicture: null,
      });
    }
  }, [user]);

  const onChange = (e) => {
    if (e.target.name === "profilePicture") {
      setFormData({ ...formData, [e.target.name]: e.target.files[0] });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleRoleChange = (roleId) => {
    setFormData({ ...formData, role: roleId });
    setShowRoleDropdown(false);
  };

  const handleStatusChange = (statusId) => {
    setFormData({ ...formData, status: statusId });
    setShowStatusDropdown(false);
  };

  const toggleRoleDropdown = () => {
    setShowRoleDropdown(!showRoleDropdown);
  };

  const toggleStatusDropdown = () => {
    setShowStatusDropdown(!showStatusDropdown);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("userName", formData.userName);
      formDataToSend.append("email", formData.email);
      formDataToSend.append("dob", formData.dob);
      formDataToSend.append("password", formData.password);
      formDataToSend.append("firstName", formData.firstName);
      formDataToSend.append("lastName", formData.lastName);
      formDataToSend.append("role", formData.role);
      formDataToSend.append("status", formData.status);
      formDataToSend.append("phoneNumber", formData.phoneNumber);
      formDataToSend.append("address", formData.address);
      if (formData.profilePicture) {
        formDataToSend.append("profilePicture", formData.profilePicture);
      }

      await dispatch(updateUser(id, formDataToSend));
      if (!error) {
        navigate(`/users/${id}`);
      }
    } catch (err) {
      console.log("Update User Error:", err);
    }
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border text-dark" role="status"></div>
      </div>
    );
  }

  return (
    <div className="content-wrapper d-flex justify-content-center">
      <div className="container mb-5">
        <ContentHeader title="Edit User" link="/users/view" />
        <section className="content">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card card-dark">
                <div className="card-header" />
                <form onSubmit={onSubmit}>
                  <div className="card-body">
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="userName">User Name</label>
                        <input
                          type="text"
                          name="userName"
                          id="userName"
                          value={formData.userName}
                          onChange={onChange}
                          className="form-control"
                          required
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="email">Email</label>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          value={formData.email}
                          onChange={onChange}
                          className="form-control"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="dob">Date of Birth</label>
                        <input
                          type="date"
                          name="dob"
                          id="dob"
                          value={formData.dob}
                          onChange={onChange}
                          className="form-control"
                          required
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="password">Password</label>
                        <input
                          type="password"
                          name="password"
                          id="password"
                          value={formData.password}
                          onChange={onChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="firstName">First Name</label>
                        <input
                          type="text"
                          name="firstName"
                          id="firstName"
                          value={formData.firstName}
                          onChange={onChange}
                          className="form-control"
                          required
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="lastName">Last Name</label>
                        <input
                          type="text"
                          name="lastName"
                          id="lastName"
                          value={formData.lastName}
                          onChange={onChange}
                          className="form-control"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="role">Role</label>
                        <div className="dropdown">
                          <button
                            type="button"
                            className="btn btn-light text-dark w-100 d-flex justify-content-between align-items-center"
                            onClick={toggleRoleDropdown}
                            style={{
                              backgroundColor: "white",
                              border: "1px solid #ced4da",
                              transition: "background-color 0.3s",
                            }}
                          >
                            <span>
                              {formData.role
                                ? roles.find((r) => r.id === formData.role).name
                                : "Select Role"}
                            </span>
                            <i className="fa fa-caret-down"></i>
                          </button>
                          {showRoleDropdown && (
                            <div className="dropdown-menu w-100 show">
                              <div
                                className="dropdown-menu-inner"
                                style={{
                                  maxHeight: "200px",
                                  overflowY: "auto",
                                }}
                              >
                                {roles.map((r) => (
                                  <div
                                    key={r.id}
                                    className="dropdown-item"
                                    onClick={() => handleRoleChange(r.id)}
                                    style={{
                                      color: "black",
                                      backgroundColor: "white",
                                      transition: "background-color 0.3s",
                                    }}
                                    onMouseEnter={(e) =>
                                      (e.target.style.backgroundColor =
                                        "#f8f9fa")
                                    }
                                    onMouseLeave={(e) =>
                                      (e.target.style.backgroundColor = "white")
                                    }
                                  >
                                    {r.name}
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="form-group col-md-6">
                        <label htmlFor="status">Status</label>
                        <div className="dropdown">
                          <button
                            type="button"
                            className="btn btn-light text-dark w-100 d-flex justify-content-between align-items-center"
                            onClick={toggleStatusDropdown}
                            style={{
                              backgroundColor: "white",
                              border: "1px solid #ced4da",
                              transition: "background-color 0.3s",
                            }}
                          >
                            <span>
                              {formData.status
                                ? statuses.find((s) => s.id === formData.status)
                                    .name
                                : "Select Status"}
                            </span>
                            <i className="fa fa-caret-down"></i>
                          </button>
                          {showStatusDropdown && (
                            <div className="dropdown-menu w-100 show">
                              <div
                                className="dropdown-menu-inner"
                                style={{
                                  maxHeight: "200px",
                                  overflowY: "auto",
                                }}
                              >
                                {statuses.map((s) => (
                                  <div
                                    key={s.id}
                                    className="dropdown-item"
                                    onClick={() => handleStatusChange(s.id)}
                                    style={{
                                      color: "black",
                                      backgroundColor: "white",
                                      transition: "background-color 0.3s",
                                    }}
                                    onMouseEnter={(e) =>
                                      (e.target.style.backgroundColor =
                                        "#f8f9fa")
                                    }
                                    onMouseLeave={(e) =>
                                      (e.target.style.backgroundColor = "white")
                                    }
                                  >
                                    {s.name}
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="phoneNumber">Phone</label>
                        <input
                          type="text"
                          name="phoneNumber"
                          id="phoneNumber"
                          value={formData.phoneNumber}
                          onChange={onChange}
                          className="form-control"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="address">Address</label>
                        <input
                          type="text"
                          name="address"
                          id="address"
                          value={formData.address}
                          onChange={onChange}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="profilePicture">Profile Picture</label>
                      <input
                        type="file"
                        name="profilePicture"
                        id="profilePicture"
                        onChange={onChange}
                        className="form-control"
                        accept="image/*"
                      />
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="col-12 mt-5 text-center">
                      <button
                        className="btn btn-secondary mx-5"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </button>
                      <input
                        type="submit"
                        value="Save Changes"
                        className="btn btn-success mx-5"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default EditUser;
