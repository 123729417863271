import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  faqs: [],
  faq: null,
  loading: true,
  error: null,
};

const faqSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {
    getFaqsSuccess: (state, action) => {
      state.faqs = action.payload;
      state.loading = false;
    },
    getFaqSuccess: (state, action) => {
      state.faq = action.payload;
      state.loading = false;
    },
    createFaqSuccess: (state, action) => {
      state.faqs.push(action.payload);
      state.loading = false;
    },
    updateFaqSuccess: (state, action) => {
      const index = state.faqs.findIndex(
        (faq) => faq._id === action.payload._id
      );
      if (index !== -1) {
        state.faqs[index] = action.payload;
      }
      state.loading = false;
    },
    deleteFaqSuccess: (state, action) => {
      state.faqs = state.faqs.filter((faq) => faq._id !== action.payload);
      state.loading = false;
    },
    faqError: (state, action) => {
      state.loading = false;
      state.error = action.payload || "An error occurred";
    },
  },
});

export const {
  getFaqsSuccess,
  getFaqSuccess,
  createFaqSuccess,
  updateFaqSuccess,
  deleteFaqSuccess,
  faqError,
} = faqSlice.actions;
export default faqSlice.reducer;
