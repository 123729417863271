import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBanner, updateBanner } from "../../../redux/actions/banner";
import ContentHeader from "../../ContentHeader";
import { useParams, useNavigate } from "react-router-dom";

function EditBanner() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: "",
    subtitle: "",
    bannerImage: null,
    status: "approve",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const dispatch = useDispatch();
  const { error, loading, banner } = useSelector((state) => state.banner);

  const { title, bannerImage, status, subtitle } = formData;

  useEffect(() => {
    dispatch(getBanner(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (banner) {
      setFormData({
        title: banner.title || "",
        subtitle: banner.subtitle || "",
        status: banner.status || "approve",
      });
      setCurrentImage(banner.bannerImage);
    }
  }, [banner]);

  const onChange = (e) => {
    if (e.target.name === "bannerImage") {
      setFormData({ ...formData, [e.target.name]: e.target.files[0] });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("title", title);
      formDataToSend.append("subtitle", subtitle);
      formDataToSend.append("status", status);
      if (bannerImage) {
        formDataToSend.append("bannerImage", bannerImage);
      }

      await dispatch(updateBanner(id, formDataToSend));
    } catch (err) {
      console.error("Update Banner Error:", err);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border text-dark" role="status"></div>
      </div>
    );
  }

  return (
    <div className="content-wrapper d-flex justify-content-center">
      <div className="container mb-5">
        <ContentHeader title="Edit Banner" link="/banners/view" />
        <section className="content">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card card-dark">
                <div className="card-header bg-dark text-white">
                  <h3 className="card-title mb-0">Banner Information</h3>
                </div>
                <form onSubmit={onSubmit}>
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="title" className="form-label">
                        Title
                      </label>
                      <input
                        type="text"
                        name="title"
                        id="title"
                        value={title}
                        onChange={onChange}
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="subtitle" className="form-label">
                        Subtitle
                      </label>
                      <textarea
                        name="subtitle"
                        id="subtitle"
                        value={subtitle}
                        onChange={onChange}
                        className="form-control"
                        rows="6"
                        required
                      />
                    </div>
                    <div className="form-row">
                      <div className="form-group col-lg-6">
                        <label htmlFor="bannerImage" className="form-label">
                          Banner Image
                        </label>
                        <input
                          type="file"
                          name="bannerImage"
                          id="bannerImage"
                          onChange={onChange}
                          className="form-control"
                          accept="image/*"
                        />
                        {currentImage && (
                          <img
                            src={currentImage}
                            alt="Current Banner"
                            className="mt-2"
                            style={{ maxWidth: "200px" }}
                          />
                        )}
                      </div>
                      <div className="form-group col-lg-6">
                        <label htmlFor="status" className="form-label">
                          Status
                        </label>
                        <select
                          name="status"
                          id="status"
                          value={status}
                          onChange={onChange}
                          className="form-control"
                        >
                          <option value="approve">Approve</option>
                          <option value="pending">Pending</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="d-flex justify-content-between">
                      <button
                        onClick={() => navigate(-1)}
                        className="btn btn-secondary btn-md"
                      >
                        <i className="fas fa-times mr-2"></i>Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-success mx-5"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm btn-md"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Updating...
                          </>
                        ) : (
                          <>
                            <i className="fas fa-save mr-2"></i> Update Banner
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default EditBanner;
