import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { getUser } from "../../../redux/actions/user";
import ContentHeader from "../../ContentHeader";

const UserDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, loading, error } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getUser(id));
  }, [dispatch, id]);

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border text-primary" role="status"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="content-wrapper bg-light py-5">
        <div className="container">
          <ContentHeader title="Error" link="/users/view" />
          <div className="alert alert-danger">Error loading user: {error}</div>
          <button
            onClick={() => navigate("/users/view")}
            className="btn btn-secondary"
          >
            Back to Users
          </button>
        </div>
      </div>
    );
  }

  if (!user) {
    return (
      <div className="content-wrapper bg-light py-5">
        <div className="container">
          <ContentHeader title="User Not Found" link="/users/view" />
          <div className="alert alert-warning">
            The requested user could not be found.
          </div>
          <button
            onClick={() => navigate("/users/view")}
            className="btn btn-secondary"
          >
            Back to Users
          </button>
        </div>
      </div>
    );
  }

  const {
    userName,
    email,
    dob,
    firstName,
    lastName,
    role,
    status,
    phoneNumber,
    address,
    profilePicture,
  } = user;

  return (
    <div className="content-wrapper bg-light py-5">
      <div className="container">
        <ContentHeader title="User Details" link="/users/view" />
        <section className="content">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card shadow-sm">
                <div className="card-header bg-dark text-white">
                  <h3 className="card-title mb-0">{userName}'s Profile</h3>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4 mb-4">
                      <img
                        src={`${profilePicture}`}
                        alt="Profile"
                        className="img-fluid rounded"
                      />
                    </div>
                    <div className="col-md-8">
                      <table className="table table-borderless">
                        <tbody>
                          <tr>
                            <th scope="row">Username</th>
                            <td>{userName}</td>
                          </tr>
                          <tr>
                            <th scope="row">Email</th>
                            <td>{email}</td>
                          </tr>
                          <tr>
                            <th scope="row">Date of Birth</th>
                            <td>
                              {new Date(dob).toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              })}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">First Name</th>
                            <td>{firstName}</td>
                          </tr>
                          <tr>
                            <th scope="row">Last Name</th>
                            <td>{lastName}</td>
                          </tr>
                          <tr>
                            <th scope="row">Role</th>
                            <td>
                              <span className="badge bg-info">{role}</span>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Status</th>
                            <td>
                              <span
                                className={`badge ${
                                  status === "active"
                                    ? "bg-success"
                                    : "bg-danger"
                                }`}
                              >
                                {status}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Phone</th>
                            <td>{phoneNumber}</td>
                          </tr>
                          <tr>
                            <th scope="row">Address</th>
                            <td>{address}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="card-footer bg-light">
                  <button
                    onClick={() => navigate("/users/view")}
                    className="btn btn-secondary"
                  >
                    Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default UserDetails;
