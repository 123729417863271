import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { getTariff } from "../../../redux/actions/tariff";
import ContentHeader from "../../ContentHeader";

function TariffDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { tariff, loading, error } = useSelector((state) => state.tariff);

  useEffect(() => {
    dispatch(getTariff(id));
  }, [dispatch, id]);

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border text-dark" role="status"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="content-wrapper bg-light">
        <div className="container py-5">
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
          <button
            onClick={() => navigate("/tariffs/view")}
            className="btn btn-secondary"
          >
            <i className="fas fa-arrow-left me-1"></i> Back to Tariffs
          </button>
        </div>
      </div>
    );
  }

  if (!tariff || !tariff.sections || tariff.sections.length === 0) {
    return (
      <div className="content-wrapper bg-light">
        <div className="container py-5">
          <div className="alert alert-info" role="alert">
            No tariff data available.
          </div>
          <button
            onClick={() => navigate("/tariffs/view")}
            className="btn btn-secondary"
          >
            <i className="fas fa-arrow-left me-1"></i> Back to Tariffs
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="content-wrapper bg-light">
      <div className="container py-5">
        <ContentHeader title="Tariff Details" link="/tariffs/view" />
        <section className="content">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card shadow">
                <div className="card-header bg-dark text-white">
                  <h3 className="card-title mb-0">{tariff.tableTitle}</h3>
                </div>
                <div className="card-body">
                  <p>
                    <strong>Effective Date:</strong>{" "}
                    {new Date(tariff.effectiveDate).toLocaleDateString()}
                  </p>
                  <p>
                    <strong>Status:</strong>{" "}
                    <span
                      className={`badge ${
                        tariff.status === "approve"
                          ? "bg-success"
                          : "bg-warning"
                      }`}
                    >
                      {tariff.status}
                    </span>
                  </p>

                  {tariff.sections.map((section, sectionIndex) => (
                    <div key={sectionIndex} className="mt-4">
                      <h4>{section.title}</h4>
                      <table className="table table-bordered table-hover">
                        <thead
                          style={{
                            backgroundColor: "#0e3c6e",
                            color: "#ffffff",
                          }}
                        >
                          <tr>
                            {section.headers.map((header, index) => (
                              <th key={index}>{header}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {section.rows.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                              {row.items.map((item, itemIndex) => (
                                <td key={itemIndex}>
                                  {Object.values(item)[0]}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ))}
                </div>
                <div className="card-footer bg-light">
                  <button
                    onClick={() => navigate("/tariffs/view")}
                    className="btn btn-secondary"
                  >
                    <i className="fas fa-arrow-left me-1"></i> Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default TariffDetails;
