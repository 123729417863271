import { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { connect, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { logoutUser } from "../redux/actions/auth";

Modal.setAppElement("#root");

// Memoized selector for user
const selectAuth = (state) => state.auth;

const selectUser = createSelector([selectAuth], (auth) => auth.user || {});

const Sidebar = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeNavItem, setActiveNavItem] = useState(null);
  const user = useSelector(selectUser);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleLogout = () => {
    props.logoutUser();
    closeModal();
  };

  const toggleNavItem = (navItem) => {
    setActiveNavItem(activeNavItem === navItem ? null : navItem);
  };

  return (
    <>
      <style jsx>{`
        .main-sidebar {
          height: 100vh;
          overflow-y: auto;
        }
      `}</style>
      <aside className="main-sidebar sidebar-dark-secondary text-white elevation-4 position-fixed">
        {/* Brand Logo */}
        <Link to="/" className="brand-link">
          <span className="brand-text font-weight-light">
            AccessBank Liberia
          </span>
        </Link>
        {/* Sidebar */}
        <div className="sidebar">
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">
              <Link to={`/users/${user._id}`}>
                <img
                  src={
                    user.profilePicture
                      ? user.profilePicture
                      : `/assets/img/BM_3.png`
                  }
                  className="img-circle elevation-2"
                  alt="Profile"
                />
              </Link>
            </div>
            <div className="info">
              <Link to={`/users/${user._id}`} className="d-block">
                {user.firstName && user.lastName
                  ? `${user.firstName} ${user.lastName}`
                  : "User"}
              </Link>
            </div>
          </div>

          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item ">
                <Link
                  to="/"
                  className="nav-link active bg-white text-dark rounded"
                >
                  <i className="nav-icon fas fa-tachometer-alt" />
                  <p>Dashboard</p>
                </Link>
              </li>

              {/* Users Starts */}
              <li
                className={`nav-item ${
                  activeNavItem === "users" ? "menu-open" : ""
                }`}
              >
                <Link
                  to="#"
                  className="nav-link"
                  onClick={() => toggleNavItem("users")}
                >
                  <i className="nav-icon fas fa-solid fa-users" />
                  <p>
                    Users
                    <i className="fas fa-angle-left right" />
                  </p>
                </Link>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/users/view" className="nav-link">
                      <i className="far fa-circle nav-icon" />
                      <p>View Users</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/users/add" className="nav-link">
                      <i className="far fa-circle nav-icon" />
                      <p>Add User</p>
                    </Link>
                  </li>
                </ul>
              </li>
              {/* Users Ends */}

              {/* Banner Starts */}
              <li
                className={`nav-item ${
                  activeNavItem === "banners" ? "menu-open" : ""
                }`}
              >
                <Link
                  to="#"
                  className="nav-link"
                  onClick={() => toggleNavItem("banners")}
                >
                  <i className="nav-icon fas fa-solid fa-flag" />
                  <p>
                    Banners
                    <i className="fas fa-angle-left right" />
                  </p>
                </Link>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/banners/view" className="nav-link">
                      <i className="far fa-circle nav-icon" />
                      <p>View Banners</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/banners/add" className="nav-link">
                      <i className="far fa-circle nav-icon" />
                      <p>Add Banner</p>
                    </Link>
                  </li>
                </ul>
              </li>
              {/* Banners Ends */}

              {/* Services Starts */}
              <li
                className={`nav-item ${
                  activeNavItem === "services" ? "menu-open" : ""
                }`}
              >
                <Link
                  to="#"
                  className="nav-link"
                  onClick={() => toggleNavItem("services")}
                >
                  <i className="nav-icon fas fa-solid fa-cog" />
                  <p>
                    Services
                    <i className="fas fa-angle-left right" />
                  </p>
                </Link>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/services/view" className="nav-link">
                      <i className="far fa-circle nav-icon" />
                      <p>View Services</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/services/add" className="nav-link">
                      <i className="far fa-circle nav-icon" />
                      <p>Add Service</p>
                    </Link>
                  </li>
                </ul>
              </li>
              {/* Services Ends */}

              {/* Team Members Starts */}
              <li
                className={`nav-item ${
                  activeNavItem === "teamMembers" ? "menu-open" : ""
                }`}
              >
                <Link
                  to="#"
                  className="nav-link"
                  onClick={() => toggleNavItem("teamMembers")}
                >
                  <i className="nav-icon fas fa-solid fa-user-circle" />
                  <p>
                    Team Members
                    <i className="fas fa-angle-left right" />
                  </p>
                </Link>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/team-members/view" className="nav-link">
                      <i className="far fa-circle nav-icon" />
                      <p>View Team Members</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/team-members/add" className="nav-link">
                      <i className="far fa-circle nav-icon" />
                      <p>Add Team Member</p>
                    </Link>
                  </li>
                </ul>
              </li>
              {/* Team Members Ends */}

              {/* Jobs Starts */}
              {/* <li
              className={`nav-item ${
                activeNavItem === "jobs" ? "menu-open" : ""
              }`}
            >
              <Link
                to="#"
                className="nav-link"
                onClick={() => toggleNavItem("jobs")}
              >
                <i className="nav-icon fas fa-solid fa-bullhorn" />
                <p>
                  Jobs
                  <i className="fas fa-angle-left right" />
                </p>
              </Link>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link to="/jobs/view" className="nav-link">
                    <i className="far fa-circle nav-icon" />
                    <p>View Jobs</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/jobs/add" className="nav-link">
                    <i className="far fa-circle nav-icon" />
                    <p>Add Job</p>
                  </Link>
                </li>
              </ul>
            </li> */}
              {/* Jobs Ends */}

              {/* Testimonials Starts */}
              {/* <li
              className={`nav-item ${
                activeNavItem === "testimonials" ? "menu-open" : ""
              }`}
            >
              <Link
                to="#"
                className="nav-link"
                onClick={() => toggleNavItem("testimonials")}
              >
                <i className="nav-icon fas fa-solid fa-quote-right" />
                <p>
                  Testimonials
                  <i className="fas fa-angle-left right" />
                </p>
              </Link>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link to="/testimonials/view" className="nav-link">
                    <i className="far fa-circle nav-icon" />
                    <p>View Testimonials</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/testimonials/add" className="nav-link">
                    <i className="far fa-circle nav-icon" />
                    <p>Add Testimonial</p>
                  </Link>
                </li>
              </ul>
            </li> */}
              {/* Testimonial Ends */}

              {/* Blogs Starts */}
              {/* <li
              className={`nav-item ${
                activeNavItem === "blogs" ? "menu-open" : ""
              }`}
            >
              <Link
                to="#"
                className="nav-link"
                onClick={() => toggleNavItem("blogs")}
              >
                <i className="nav-icon fas fa-solid fa-blog" />
                <p>
                  Blogs
                  <i className="fas fa-angle-left right" />
                </p>
              </Link>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link to="/blogs/view" className="nav-link">
                    <i className="far fa-circle nav-icon" />
                    <p>View Blogs</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/blogs/add" className="nav-link">
                    <i className="far fa-circle nav-icon" />
                    <p>Add Blog</p>
                  </Link>
                </li>
              </ul>
            </li> */}
              {/* Blogs Ends */}

              {/* Faqs Starts */}
              <li
                className={`nav-item ${
                  activeNavItem === "faqs" ? "menu-open" : ""
                }`}
              >
                <Link
                  to="#"
                  className="nav-link"
                  onClick={() => toggleNavItem("faqs")}
                >
                  <i className="nav-icon fas fa-solid fa-question-circle" />
                  <p>
                    Faqs
                    <i className="fas fa-angle-left right" />
                  </p>
                </Link>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/faqs/view" className="nav-link">
                      <i className="far fa-circle nav-icon" />
                      <p>View Faqs</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/faqs/add" className="nav-link">
                      <i className="far fa-circle nav-icon" />
                      <p>Add Faq</p>
                    </Link>
                  </li>
                </ul>
              </li>
              {/* Faqs Ends */}

              {/* Tariffs Starts */}
              <li
                className={`nav-item ${
                  activeNavItem === "tariffs" ? "menu-open" : ""
                }`}
              >
                <Link
                  to="#"
                  className="nav-link"
                  onClick={() => toggleNavItem("tariffs")}
                >
                  <i className="nav-icon fas fa-solid fa-tag" />
                  <p>
                    Tariffs
                    <i className="fas fa-angle-left right" />
                  </p>
                </Link>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/tariffs/view" className="nav-link">
                      <i className="far fa-circle nav-icon" />
                      <p>View Tariffs</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/tariffs/add" className="nav-link">
                      <i className="far fa-circle nav-icon" />
                      <p>Add Tariff</p>
                    </Link>
                  </li>
                </ul>
              </li>
              {/* Tariffs Ends */}

              {/* Reports Starts */}
              <li
                className={`nav-item ${
                  activeNavItem === "reports" ? "menu-open" : ""
                }`}
              >
                <Link
                  to="#"
                  className="nav-link"
                  onClick={() => toggleNavItem("reports")}
                >
                  <i className="nav-icon fas fa-solid fa-file-alt" />
                  <p>
                    Reports
                    <i className="fas fa-angle-left right" />
                  </p>
                </Link>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/reports/view" className="nav-link">
                      <i className="far fa-circle nav-icon" />
                      <p>View Reports</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/reports/add" className="nav-link">
                      <i className="far fa-circle nav-icon" />
                      <p>Add Report</p>
                    </Link>
                  </li>
                </ul>
              </li>
              {/* Reports Ends */}

              {/* Contacts Starts */}
              <li
                className={`nav-item ${
                  activeNavItem === "teamMembers" ? "menu-open" : ""
                }`}
              >
                <Link
                  to="/contacts/view"
                  className="nav-link"
                  // onClick={() => toggleNavItem("teamMembers")}
                >
                  <i className="nav-icon fas fa-solid fa-address-card" />
                  <p>Contacts</p>
                </Link>
              </li>
              {/* Contacts Ends */}

              <li className="nav-item bg-danger text-white mt-5 rounded">
                <Link className="nav-link" onClick={openModal}>
                  <i className="nav-icon fas fa-arrow-right" />
                  <p>LogOut</p>
                </Link>
              </li>
              <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Example Modal"
                overlayClassName="modal-backdrop"
                ariaHideApp={false}
                style={{
                  overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black
                  },
                  content: {
                    backgroundColor: "transparent", // Make modal content background transparent
                    border: "none",
                  },
                }}
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        LogOut
                      </h5>
                    </div>
                    <div className="modal-body">
                      Are you sure you want to log out? Any unsaved changes
                      might be lost.
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn-close btn btn-success"
                        onClick={closeModal}
                        aria-label="close"
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={handleLogout}
                      >
                        LogOut
                      </button>
                    </div>
                  </div>
                </div>
              </Modal>
            </ul>
          </nav>
        </div>
      </aside>
    </>
  );
};

const mapDispatchToProps = {
  logoutUser,
};

export default connect(null, mapDispatchToProps)(Sidebar);
