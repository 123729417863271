import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  testimonials: [],
  testimonial: null,
  loading: true,
  error: null,
};

const testimonialSlice = createSlice({
  name: "testimonial",
  initialState,
  reducers: {
    getTestimonialsSuccess: (state, action) => {
      state.testimonials = action.payload;
      state.loading = false;
    },
    getTestimonialSuccess: (state, action) => {
      state.testimonial = action.payload;
      state.loading = false;
    },
    createTestimonialSuccess: (state, action) => {
      state.testimonials.push(action.payload);
      state.loading = false;
    },
    updateTestimonialSuccess: (state, action) => {
      const index = state.testimonials.findIndex(
        (testimonial) => testimonial._id === action.payload._id
      );
      if (index !== -1) {
        state.testimonials[index] = action.payload;
      }
      state.loading = false;
    },
    deleteTestimonialSuccess: (state, action) => {
      state.testimonials = state.testimonials.filter(
        (testimonial) => testimonial._id !== action.payload
      );
      state.loading = false;
    },
    testimonialError: (state, action) => {
      state.loading = false;
      state.error = action.payload || "An error occurred";
    },
  },
});

export const {
  getTestimonialsSuccess,
  getTestimonialSuccess,
  createTestimonialSuccess,
  updateTestimonialSuccess,
  deleteTestimonialSuccess,
  testimonialError,
} = testimonialSlice.actions;
export default testimonialSlice.reducer;
