import { Route, Routes, Navigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Sidebar from "../components/SideBar";
import AddService from "../components/custom/Service/AddService";
import ViewServices from "../components/custom/Service/ViewServices";
import EditService from "../components/custom/Service/EditService";
import ServiceDetails from "../components/custom/Service/ServiceDetails";

function Service() {
  return (
    <div>
      <Navbar />
      <Sidebar />
      <Routes>
        <Route path="/add" element={<AddService />} />
        <Route path="/view" element={<ViewServices />} />
        <Route path="/edit/:id" element={<EditService />} />
        <Route path="/:id" element={<ServiceDetails />} />
        <Route path="/*" element={<Navigate to="/view" replace />} />
      </Routes>
    </div>
  );
}
export default Service;
