import { Route, Routes, Navigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Sidebar from "../components/SideBar";
import AddUser from "../components/custom/User/AddUser";
import ViewUsers from "../components/custom/User/ViewUsers";
import EditUser from "../components/custom/User/EditUser";
import UserDetails from "../components/custom/User/UserDetails";

const User = () => {
  return (
    <div>
      <Navbar />
      <Sidebar />
      <Routes>
        <Route path="/add" element={<AddUser />} />
        <Route path="/view" element={<ViewUsers />} />
        <Route path="/edit/:id" element={<EditUser />} />
        <Route path="/:id" element={<UserDetails />} />
        <Route path="/*" element={<Navigate to="/view" replace />} />
      </Routes>
    </div>
  );
};

export default User;
