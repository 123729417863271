import { Route, Routes, Navigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Sidebar from "../components/SideBar";
import AddReport from "../components/custom/Report/AddReport";
import ViewReports from "../components/custom/Report/ViewReports";
import EditReport from "../components/custom/Report/EditReport";
import ReportDetails from "../components/custom/Report/ReportDetails";

function Report() {
  return (
    <div>
      <Navbar />
      <Sidebar />
      <Routes>
        <Route path="/add" element={<AddReport />} />
        <Route path="/view" element={<ViewReports />} />
        <Route path="/edit/:id" element={<EditReport />} />
        <Route path="/:id" element={<ReportDetails />} />
        <Route path="/*" element={<Navigate to="/view" replace />} />
      </Routes>
    </div>
  );
}
export default Report;
