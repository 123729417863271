import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTeamMembers,
  deleteTeamMember,
} from "../../../redux/actions/teamMember";
import ContentHeader from "../../ContentHeader";
import { useNavigate } from "react-router-dom";

function ViewTeamMember() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { teamMembers, loading } = useSelector((state) => state.teamMember);

  useEffect(() => {
    dispatch(getTeamMembers());
  }, [dispatch]);

  const handleEdit = (id) => {
    navigate(`/team-members/edit/${id}`);
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      dispatch(deleteTeamMember(id));
    }
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border text-dark" role="status"></div>
      </div>
    );
  }

  return (
    <div className="content-wrapper bg-light">
      <ContentHeader title="View Team Members" link="/team-members/view" />
      <section className="content">
        <div className="container-fluid py-5">
          <div className="card shadow">
            <div className="card-header bg-dark text-white">
              <h3 className="card-title mb-0">Team Members</h3>
            </div>
            <div className="card-body p-0">
              <div className="table-responsive">
                <table className="table table-striped table-hover mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Position</th>
                      <th>Board Type</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {teamMembers.map((teamMember, index) => (
                      <tr key={teamMember._id}>
                        <td>{index + 1}</td>
                        <td>{teamMember.name}</td>
                        <td>{teamMember.position}</td>
                        <td>{teamMember.boardType}</td>
                        <td>
                          <span
                            className={`badge bg-${
                              teamMember.status === "approve"
                                ? "success"
                                : "danger"
                            } px-3 py-2`}
                          >
                            {teamMember.status}
                          </span>
                        </td>
                        <td>
                          <div className="btn-group" role="group">
                            <button
                              className="btn btn-sm btn-outline-primary mr-1"
                              onClick={() =>
                                navigate(`/team-members/${teamMember._id}`)
                              }
                            >
                              <i className="fas fa-eye me-1"></i> View
                            </button>
                            <button
                              className="btn btn-sm btn-outline-secondary mr-1"
                              onClick={() => handleEdit(teamMember._id)}
                            >
                              <i className="fas fa-edit me-1"></i> Edit
                            </button>
                            <button
                              className="btn btn-sm btn-outline-danger mr-1"
                              onClick={() => handleDelete(teamMember._id)}
                            >
                              <i className="fas fa-trash me-1"></i> Delete
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default ViewTeamMember;
