import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivateRoute from "./components/routing/PrivateRoute";
import Preloader from "./components/Preloader";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import User from "./pages/User";
import Banner from "./pages/Banner";
// import Blog from "./pages/Blog";
// import Job from "./pages/Job";
import Faqs from "./pages/Faqs";
import Report from "./pages/Report";
import Service from "./pages/Service";
import Tariff from "./pages/Tariff";
import TeamMember from "./pages/TeamMember";
// import Testimonial from "./pages/Testimonial";
import Contact from "./pages/Contact";
import Login from "./pages/Login";
import "./App.css";

function App() {
  return (
    <Router>
      <Preloader />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<Home />} />
          <Route path="/users/*" element={<User />} />
          <Route path="/banners/*" element={<Banner />} />
          {/* <Route path="/blogs/*" element={<Blog />} /> */}
          {/* <Route path="/jobs/*" element={<Job />} /> */}
          <Route path="/faqs/*" element={<Faqs />} />
          <Route path="/reports/*" element={<Report />} />
          <Route path="/services/*" element={<Service />} />
          <Route path="/tariffs/*" element={<Tariff />} />
          <Route path="/team-members/*" element={<TeamMember />} />
          {/* <Route path="/testimonials/*" element={<Testimonial />} /> */}
          <Route path="/contacts/*" element={<Contact />} />
        </Route>
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
