import { Route, Routes, Navigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Sidebar from "../components/SideBar";
import ViewContacts from "../components/custom/Contact/ViewContacts";
import ContactDetails from "../components/custom/Contact/ContactDetails";

function Contact() {
  return (
    <div>
      <Navbar />
      <Sidebar />
      <Routes>
        <Route path="/view" element={<ViewContacts />} />
        <Route path="/:id" element={<ContactDetails />} />
        <Route path="/*" element={<Navigate to="/view" replace />} />
      </Routes>
    </div>
  );
}
export default Contact;
