import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Preloader from "../Preloader";

const PrivateRoute = () => {
  const { isAuthenticated, loading } = useSelector((state) => state.auth);

  if (loading) {
    return <Preloader />;
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
