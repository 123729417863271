import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { getReport } from "../../../redux/actions/report";
import ContentHeader from "../../ContentHeader";

function ReportDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { report, loading, error } = useSelector((state) => state.report);

  useEffect(() => {
    dispatch(getReport(id));
  }, [dispatch, id]);

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border text-dark" role="status"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="content-wrapper bg-light">
        <ContentHeader title="Error" link="/reports/view" />
        <section className="content">
          <div className="container">
            <div className="alert alert-danger">
              Error loading report: {error}
            </div>
            <button
              onClick={() => navigate("/reports/view")}
              className="btn btn-secondary"
            >
              <i className="fas fa-arrow-left mr-1"></i> Back
            </button>
          </div>
        </section>
      </div>
    );
  }

  if (!report) {
    return (
      <div className="content-wrapper bg-light">
        <ContentHeader title="Report Not Found" link="/reports/view" />
        <section className="content">
          <div className="container">
            <div className="alert alert-warning">
              The requested report could not be found.
            </div>
            <button
              onClick={() => navigate("/reports/view")}
              className="btn btn-secondary"
            >
              <i className="fas fa-arrow-left mr-1"></i> Back
            </button>
          </div>
        </section>
      </div>
    );
  }

  const { title, downloadFile, status } = report;

  return (
    <div className="content-wrapper bg-light">
      <ContentHeader title="Report Details" link="/reports/view" />
      <section className="content">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card card-dark shadow">
                <div className="card-header bg-dark text-white">
                  <h3 className="card-title">{title}</h3>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <table className="table table-bordered table-hover">
                        <tbody>
                          <tr>
                            <th className="bg-light">Title</th>
                            <td>{title}</td>
                          </tr>
                          <tr>
                            <th className="bg-light">Status</th>
                            <td>
                              <span
                                className={`badge ${
                                  status === "approved"
                                    ? "bg-success"
                                    : "bg-warning"
                                }`}
                              >
                                {status}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <th className="bg-light">Download File</th>
                            <td>
                              {downloadFile ? (
                                <a
                                  href={downloadFile}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="btn btn-outline-primary btn-sm"
                                >
                                  <i className="fas fa-download mr-1"></i>{" "}
                                  Download
                                </a>
                              ) : (
                                <span className="text-muted">
                                  No file available
                                </span>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <button
                    onClick={() => navigate("/reports/view")}
                    className="btn btn-secondary"
                  >
                    <i className="fas fa-arrow-left mr-1"></i> Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ReportDetails;
