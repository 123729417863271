import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getServices, deleteService } from "../../../redux/actions/service";
import ContentHeader from "../../ContentHeader";
import { useNavigate } from "react-router-dom";

function ViewServices() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { services, loading } = useSelector((state) => state.service);

  useEffect(() => {
    dispatch(getServices());
  }, [dispatch]);

  const handleEdit = (id) => {
    navigate(`/services/edit/${id}`);
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this service?")) {
      dispatch(deleteService(id));
    }
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border text-dark" role="status"></div>
      </div>
    );
  }

  return (
    <div className="content-wrapper bg-light">
      <ContentHeader title="View Services" link="/services/view" />
      <section className="content py-4">
        <div className="container-fluid">
          <div className="card shadow">
            <div className="card-header bg-dark text-white">
              <h3 className="card-title mb-0">Services</h3>
            </div>
            <div className="card-body p-0">
              <div className="table-responsive">
                <table className="table table-striped table-hover mb-0">
                  <thead className="table-light">
                    <tr>
                      <th>#</th>
                      <th>Content Title</th>
                      <th>Banking Type</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {services.map((service, index) => (
                      <tr key={service._id}>
                        <td>{index + 1}</td>
                        <td>{service.tabs[0]?.content?.title || "N/A"}</td>

                        <td>{service.bankingType}</td>

                        <td>
                          <span
                            className={`badge bg-${
                              service.status === "approve"
                                ? "success"
                                : "warning"
                            } px-3 py-2`}
                          >
                            {service.status}
                          </span>
                        </td>
                        <td>
                          <div className="btn-group" role="group">
                            <button
                              className="btn btn-outline-primary btn-sm mr-1"
                              onClick={() =>
                                navigate(`/services/${service._id}`)
                              }
                            >
                              <i className="fas fa-eye me-1"></i> View
                            </button>
                            <button
                              className="btn btn-outline-secondary btn-sm mr-1"
                              onClick={() => handleEdit(service._id)}
                            >
                              <i className="fas fa-edit me-1"></i> Edit
                            </button>
                            <button
                              className="btn btn-outline-danger btn-sm"
                              onClick={() => handleDelete(service._id)}
                            >
                              <i className="fas fa-trash me-1"></i> Delete
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ViewServices;
