import axios from "axios";
import {
  getContactsStart,
  getContactsSuccess,
  getContactStart,
  getContactSuccess,
  deleteContactSuccess,
  contactError,
} from "../reducers/contactSlice";

const API_URL = process.env.REACT_APP_API_URL;

const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  return token ? { Authorization: `Bearer ${token}` } : {};
};

export const getContacts = () => async (dispatch) => {
  dispatch(getContactsStart());
  try {
    const res = await axios.get(`${API_URL}/contacts`, {
      headers: getAuthHeader(),
    });
    dispatch(getContactsSuccess(res.data.data));
  } catch (err) {
    console.error("Error fetching contacts:", err);
    dispatch(contactError(err.response?.data?.error || "An error occurred"));
  }
};

export const getContact = (id) => async (dispatch) => {
  dispatch(getContactStart());
  try {
    const res = await axios.get(`${API_URL}/contacts/${id}`, {
      headers: getAuthHeader(),
    });
    dispatch(getContactSuccess(res.data.data));
  } catch (err) {
    dispatch(contactError(err.response?.data?.error || "An error occurred"));
  }
};

export const deleteContact = (id) => async (dispatch) => {
  try {
    await axios.delete(`${API_URL}/contacts/${id}`, {
      headers: getAuthHeader(),
    });
    dispatch(deleteContactSuccess(id));
  } catch (err) {
    dispatch(contactError(err.response?.data?.error || "An error occurred"));
  }
};
