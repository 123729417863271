import axios from "axios";
import {
  getServicesSuccess,
  getServiceSuccess,
  createServiceSuccess,
  updateServiceSuccess,
  deleteServiceSuccess,
  serviceError,
} from "../reducers/serviceReducer";

const API_URL = process.env.REACT_APP_API_URL;

const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  return token ? { Authorization: `Bearer ${token}` } : {};
};

export const getServices = () => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}/bankings`, {
      headers: getAuthHeader(),
    });
    dispatch(getServicesSuccess(res.data.data));
  } catch (err) {
    dispatch(
      serviceError(err.response?.data?.error || "Failed to fetch services")
    );
  }
};

export const getService = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}/bankings/${id}`, {
      headers: getAuthHeader(),
    });
    dispatch(getServiceSuccess(res.data.data));
  } catch (err) {
    dispatch(
      serviceError(err.response?.data?.error || "Failed to fetch service")
    );
  }
};

export const createService = (serviceData) => async (dispatch) => {
  try {
    const res = await axios.post(`${API_URL}/bankings`, serviceData, {
      headers: {
        ...getAuthHeader(),
        "Content-Type": "multipart/form-data",
      },
    });

    if (res.data.success) {
      dispatch(createServiceSuccess(res.data.data));
      // Redirect after successful creation
      window.location.href = "/services/view";
    } else {
      throw new Error(res.data.error || "Failed to create service");
    }
  } catch (err) {
    dispatch(
      serviceError(
        err.response?.data?.error || err.message || "An unknown error occurred"
      )
    );
  }
};

export const updateService = (id, serviceData) => async (dispatch) => {
  try {
    const res = await axios.put(`${API_URL}/bankings/${id}`, serviceData, {
      headers: {
        ...getAuthHeader(),
        "Content-Type": "multipart/form-data",
      },
    });

    if (res.data.success) {
      dispatch(updateServiceSuccess(res.data.data));
      window.location.href = "/services/view";
    } else {
      throw new Error(res.data.error || "Failed to update service");
    }
  } catch (err) {
    const errorMessage =
      err.response?.data?.error || err.message || "An unknown error occurred";
    dispatch(serviceError(errorMessage));
    throw new Error(errorMessage);
  }
};

export const deleteService = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(`${API_URL}/bankings/${id}`, {
      headers: getAuthHeader(),
    });
    if (res.data.success) {
      dispatch(deleteServiceSuccess(id));
    } else {
      throw new Error(res.data.error || "Failed to delete service");
    }
  } catch (err) {
    dispatch(
      serviceError(err.response?.data?.error || "Failed to delete service")
    );
  }
};
