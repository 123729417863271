import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { getContact } from "../../../redux/actions/contact";
import ContentHeader from "../../ContentHeader";

function ContactDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { contact, loading } = useSelector((state) => state.contact);

  useEffect(() => {
    dispatch(getContact(id));
  }, [dispatch, id]);

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border text-dark" role="status"></div>
      </div>
    );
  }

  const { fullName, email, phone, subject, message, createdAt } = contact || {};

  return (
    <div className="content-wrapper bg-light">
      <div className="container py-5">
        <ContentHeader title="Contact Details" link="/contacts/view" />
        <section className="content">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card card-dark">
                <div className="card-header bg-dark text-white">
                  <h3 className="card-title mb-0">
                    Contact Submission Details
                  </h3>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <h5 className="mb-3">Contact Information</h5>
                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <th scope="row" className="text-muted">
                              Full Name
                            </th>
                            <td>{fullName}</td>
                          </tr>
                          <tr>
                            <th scope="row" className="text-muted">
                              Email
                            </th>
                            <td>{email}</td>
                          </tr>
                          <tr>
                            <th scope="row" className="text-muted">
                              Phone
                            </th>
                            <td>{phone || "N/A"}</td>
                          </tr>
                          <tr>
                            <th scope="row" className="text-muted">
                              Subject
                            </th>
                            <td>{subject}</td>
                          </tr>
                          <tr>
                            <th scope="row" className="text-muted">
                              Submitted On
                            </th>
                            <td>{new Date(createdAt).toLocaleString()}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-md-6">
                      <h5 className="mb-3">Message</h5>
                      <div className="card">
                        <div className="card-body">
                          <p>{message}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer bg-light">
                  <button
                    onClick={() => navigate("/contacts/view")}
                    className="btn btn-secondary"
                  >
                    <i className="fas fa-arrow-left me-2"></i> Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ContactDetails;
