import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFaqs, deleteFaq } from "../../../redux/actions/faq";
import ContentHeader from "../../ContentHeader";
import { useNavigate } from "react-router-dom";

function ViewFaqs() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { faqs, loading } = useSelector((state) => state.faq);

  useEffect(() => {
    dispatch(getFaqs());
  }, [dispatch]);

  const handleEdit = (id) => {
    navigate(`/faqs/edit/${id}`);
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      dispatch(deleteFaq(id));
    }
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border text-dark" role="status"></div>
      </div>
    );
  }

  return (
    <div className="content-wrapper bg-light">
      <ContentHeader title="View FAQs" link="/faqs/view" />
      <section className="content">
        <div className="container-fluid py-5">
          <div className="card shadow">
            <div className="card-header bg-dark text-white">
              <h3 className="card-title mb-0">FAQs List</h3>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover table-striped">
                  <thead className="thead-light">
                    <tr>
                      <th>#</th>
                      <th>Question</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {faqs.map((faq, index) => (
                      <tr key={faq._id}>
                        <td>{index + 1}</td>
                        <td>{faq.question}</td>
                        <td>
                          <span
                            className={`badge badge-${
                              faq.status === "approve" ? "success" : "warning"
                            } px-3 py-2`}
                          >
                            {faq.status}
                          </span>
                        </td>
                        <td>
                          <div className="btn-group" role="group">
                            <button
                              className="btn btn-outline-primary btn-sm mr-1"
                              onClick={() => navigate(`/faqs/${faq._id}`)}
                            >
                              <i className="fas fa-eye mr-1"></i> View
                            </button>
                            <button
                              className="btn btn-outline-secondary btn-sm mr-1"
                              onClick={() => handleEdit(faq._id)}
                            >
                              <i className="fas fa-edit mr-1"></i> Edit
                            </button>
                            <button
                              className="btn btn-outline-danger btn-sm"
                              onClick={() => handleDelete(faq._id)}
                            >
                              <i className="fas fa-trash mr-1"></i> Delete
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default ViewFaqs;
