import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsers, deleteUser } from "../../../redux/actions/user";
import ContentHeader from "../../ContentHeader";
import { useNavigate } from "react-router-dom";

const ViewUsers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { users, loading } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const handleEdit = (id) => {
    navigate(`/users/edit/${id}`);
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      dispatch(deleteUser(id));
    }
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border text-dark" role="status"></div>
      </div>
    );
  }

  return (
    <div className="content-wrapper bg-light">
      <ContentHeader title="View Users" link="/users/view" />
      <section className="content">
        <div className="container-fluid py-5">
          <div className="card shadow">
            <div className="card-header bg-dark text-white">
              <h3 className="card-title mb-0">Users</h3>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover table-striped">
                  <thead className="table-light">
                    <tr>
                      <th>#</th>
                      <th>User Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Address</th>
                      <th>Role</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user, index) => (
                      <tr key={user._id}>
                        <td>{index + 1}</td>
                        <td>{user.userName}</td>
                        <td>{user.email}</td>
                        <td>{user.phoneNumber}</td>
                        <td>{user.address}</td>
                        <td>
                          <span className="badge bg-info">{user.role}</span>
                        </td>
                        <td>
                          <span
                            className={`badge ${
                              user.status === "active"
                                ? "bg-success"
                                : "bg-danger"
                            } px-3 py-2`}
                          >
                            {user.status}
                          </span>
                        </td>
                        <td>
                          <div className="btn-group" role="group">
                            <button
                              className="btn btn-outline-primary btn-sm mr-1"
                              onClick={() => navigate(`/users/${user._id}`)}
                            >
                              <i className="fas fa-folder me-1"></i> View
                            </button>
                            <button
                              className="btn btn-outline-secondary btn-sm mr-1"
                              onClick={() => handleEdit(user._id)}
                            >
                              <i className="fas fa-edit me-1"></i> Edit
                            </button>
                            <button
                              className="btn btn-outline-danger btn-sm"
                              onClick={() => handleDelete(user._id)}
                            >
                              <i className="fas fa-trash me-1"></i> Delete
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ViewUsers;
