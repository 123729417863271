import { Route, Routes, Navigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Sidebar from "../components/SideBar";
import AddBanner from "../components/custom/Banner/AddBanner";
import EditBanner from "../components/custom/Banner/EditBanner";
import ViewBanners from "../components/custom/Banner/ViewBanners";
import BannerDetails from "../components/custom/Banner/BannerDetails";

function Banner() {
  return (
    <div>
      <Navbar />
      <Sidebar />
      <Routes>
        <Route path="/add" element={<AddBanner />} />
        <Route path="/view" element={<ViewBanners />} />
        <Route path="/edit/:id" element={<EditBanner />} />
        <Route path="/:id" element={<BannerDetails />} />
        <Route path="/*" element={<Navigate to="/view" replace />} />
      </Routes>
    </div>
  );
}
export default Banner;
