import axios from "axios";
import {
  getReportsSuccess,
  getReportSuccess,
  createReportSuccess,
  updateReportSuccess,
  deleteReportSuccess,
  reportError,
} from "../reducers/reportReducer";

const API_URL = process.env.REACT_APP_API_URL;

const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  return token ? { Authorization: `Bearer ${token}` } : {};
};

export const getReports = () => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}/reports`, {
      headers: getAuthHeader(),
    });
    dispatch(getReportsSuccess(res.data.data));
  } catch (err) {
    dispatch(reportError());
  }
};

export const getReport = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}/reports/${id}`, {
      headers: getAuthHeader(),
    });
    dispatch(getReportSuccess(res.data.data));
  } catch (err) {
    dispatch(reportError());
  }
};

export const createReport = (reportData) => async (dispatch) => {
  try {
    const res = await axios.post(`${API_URL}/reports`, reportData, {
      headers: {
        ...getAuthHeader(),
        "Content-Type": "multipart/form-data",
      },
    });

    if (res.data.success) {
      dispatch(createReportSuccess(res.data.data));
      // Redirect after successful creation
      window.location.href = "/reports/view";
    } else {
      throw new Error(res.data.error || "Failed to create report");
    }
  } catch (err) {
    dispatch(
      reportError(
        err.response?.data?.error || err.message || "An unknown error occurred"
      )
    );
  }
};

export const updateReport = (id, reportData) => async (dispatch) => {
  try {
    const res = await axios.put(`${API_URL}/reports/${id}`, reportData, {
      headers: getAuthHeader(),
    });

    if (res.data.success) {
      dispatch(updateReportSuccess(res.data.data));
      window.location.href = "/reports/view";
    } else {
      throw new Error(res.data.error || "Failed to update report");
    }
  } catch (err) {
    dispatch(reportError());
  }
};

export const deleteReport = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(`${API_URL}/reports/${id}`, {
      headers: getAuthHeader(),
    });
    if (res.data.success) {
      dispatch(deleteReportSuccess(id));
    } else {
      throw new Error(res.data.error || "Failed to delete report");
    }
  } catch (err) {
    dispatch(reportError());
  }
};
