import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  contacts: [],
  contact: null,
  loading: false,
  error: null,
};

const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    getContactsStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    getContactsSuccess: (state, action) => {
      state.contacts = action.payload;
      state.loading = false;
      state.error = null;
    },
    getContactStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    getContactSuccess: (state, action) => {
      state.contact = action.payload;
      state.loading = false;
      state.error = null;
    },
    deleteContactSuccess: (state, action) => {
      state.contacts = state.contacts.filter(
        (contact) => contact._id !== action.payload
      );
      state.loading = false;
      state.error = null;
    },
    contactError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  getContactsStart,
  getContactsSuccess,
  getContactStart,
  getContactSuccess,
  deleteContactSuccess,
  contactError,
} = contactSlice.actions;

export default contactSlice.reducer;
