import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReports, deleteReport } from "../../../redux/actions/report";
import ContentHeader from "../../ContentHeader";
import { useNavigate } from "react-router-dom";

function ViewReport() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { reports, loading } = useSelector((state) => state.report);
  const [sortedReports, setSortedReports] = useState([]);

  useEffect(() => {
    dispatch(getReports());
  }, [dispatch]);

  useEffect(() => {
    // Sort reports by createdAt in descending order
    const sorted = [...reports].sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
    setSortedReports(sorted);
  }, [reports]);

  const handleEdit = (id) => {
    navigate(`/reports/edit/${id}`);
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this report?")) {
      dispatch(deleteReport(id));
    }
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border text-dark" role="status"></div>
      </div>
    );
  }

  return (
    <div className="content-wrapper bg-light">
      <ContentHeader title="View Reports" link="/reports/view" />
      <section className="content">
        <div className="container-fluid">
          <div className="card shadow">
            <div className="card-header bg-dark text-white">
              <h3 className="card-title">Reports (Sorted by Most Recent)</h3>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover table-striped">
                  <thead className="thead-light">
                    <tr>
                      <th>#</th>
                      <th>Title</th>
                      <th>Status</th>
                      <th>Created At</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reports.map((report, index) => (
                      <tr key={report._id}>
                        <td>{index + 1}</td>
                        <td>{report.title}</td>
                        <td>
                          <span
                            className={`badge ${
                              report.status === "approved"
                                ? "bg-success"
                                : "bg-warning"
                            } px-3 py-2`}
                          >
                            {report.status}
                          </span>
                        </td>
                        <td>{new Date(report.createdAt).toLocaleString()}</td>
                        <td>
                          <div className="btn-group" role="group">
                            <button
                              className="btn btn-outline-primary btn-sm mr-1"
                              onClick={() => navigate(`/reports/${report._id}`)}
                            >
                              <i className="fas fa-eye mr-1"></i> View
                            </button>
                            <button
                              className="btn btn-outline-secondary btn-sm mr-1"
                              onClick={() => handleEdit(report._id)}
                            >
                              <i className="fas fa-edit mr-1"></i> Edit
                            </button>
                            <button
                              className="btn btn-outline-danger btn-sm"
                              onClick={() => handleDelete(report._id)}
                            >
                              <i className="fas fa-trash mr-1"></i> Delete
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ViewReport;
