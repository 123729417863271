import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReport, updateReport } from "../../../redux/actions/report";
import ContentHeader from "../../ContentHeader";
import { useParams, useNavigate } from "react-router-dom";

function EditReport() {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    title: "",
    downloadFile: null,
    status: "approved",
  });

  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [existingDownloadFile, setExistingDownloadFile] = useState(null);

  const { title, downloadFile, status } = formData;

  const dispatch = useDispatch();
  const { error, report, loading } = useSelector((state) => state.report || {});

  useEffect(() => {
    dispatch(getReport(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (report) {
      setFormData({
        title: report.title || "",
        status: report.status || "approved",
        downloadFile: null,
      });
      setExistingDownloadFile(report.downloadFile);
    }
  }, [report]);

  const onChange = (e) => {
    if (e.target.name === "downloadFile") {
      setFormData({ ...formData, [e.target.name]: e.target.files[0] });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("title", title);
      formDataToSend.append("status", status);

      if (downloadFile) {
        formDataToSend.append("downloadFile", downloadFile);
      }

      console.log("Submitting form data:", formDataToSend);

      dispatch(updateReport(id, formDataToSend));
    } catch (err) {
      console.error("Update Report Error:", err);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border text-dark" role="status"></div>
      </div>
    );
  }

  return (
    <div className="content-wrapper bg-light d-flex justify-content-center">
      <div className="container py-5">
        <ContentHeader title="Edit Report" link="/reports/view" />
        <section className="content">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card shadow">
                <div className="card-header bg-dark text-white">
                  <h3 className="card-title">Edit Report Details</h3>
                </div>
                <form onSubmit={onSubmit}>
                  <div className="card-body">
                    <div className="form-row">
                      <div className="form-group col-lg-6">
                        <label htmlFor="title">Title</label>
                        <input
                          type="text"
                          name="title"
                          id="title"
                          value={title}
                          onChange={onChange}
                          className="form-control"
                          required
                        />
                      </div>
                      <div className="form-group col-lg-6">
                        <label htmlFor="status">Status</label>
                        <select
                          name="status"
                          id="status"
                          value={status}
                          onChange={onChange}
                          className="form-control"
                        >
                          <option value="approve">Approve</option>
                          <option value="pending">Pending</option>
                        </select>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-lg-12">
                        <label htmlFor="downloadFile">Download File</label>
                        <input
                          type="file"
                          name="downloadFile"
                          id="downloadFile"
                          onChange={onChange}
                          className="form-control"
                        />
                        {existingDownloadFile && (
                          <div className="mt-2">
                            <a
                              href={existingDownloadFile}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Current Download File
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="card-footer bg-light">
                    <div className="d-flex justify-content-between">
                      <button
                        onClick={() => navigate(-1)}
                        className="btn btn-secondary btn-md"
                      >
                        <i className="fas fa-times mr-2"></i>Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-success btn-md"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Updating...
                          </>
                        ) : (
                          <>
                            <i className="fas fa-save mr-1"></i> Update Report
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default EditReport;
