const Footer = () => {
  const getCurrentYear = () => new Date().getFullYear();
  return (
    <footer className="main-footer text-center">
      <div className="d-flex justify-content-center align-items-center">
        <p className="text-base">
          ©{getCurrentYear()} Access Bank Liberia, Powered by{" "}
          <a href="https://bigmove.dev/" target="_blank" rel="noreferrer">
            BigMove LLC.
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
