import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { getUsers } from "../redux/actions/user";
import { getBanners } from "../redux/actions/banner";
import { getTeamMembers } from "../redux/actions/teamMember";
import { getServices } from "../redux/actions/service";
// import { getJobs } from "../redux/actions/job";
import { getFaqs } from "../redux/actions/faq";
// import { getTestimonials } from "../redux/actions/testimonial";
import { getTariffs } from "../redux/actions/tariff";
import { getReports } from "../redux/actions/report";
import { getContacts } from "../redux/actions/contact";
import ContentHeader from "../components/ContentHeader";
import Navbar from "../components/Navbar";
import Sidebar from "../components/SideBar";
import InfoBox from "../components/InfoBox";

// Memoized selectors for each piece of data
const selectUsersCount = createSelector(
  (state) => state.user.users,
  (users) => (users || []).length
);

const selectBannersCount = createSelector(
  (state) => state.banner.banners,
  (banners) => (banners || []).length
);

const selectTeamMembersCount = createSelector(
  (state) => state.teamMember.teamMembers,
  (teamMembers) => (teamMembers || []).length
);

const selectServicesCount = createSelector(
  (state) => state.service.services,
  (services) => (services || []).length
);

const selectFaqsCount = createSelector(
  (state) => state.faq.faqs,
  (faqs) => (faqs || []).length
);

// const selectTestimonialsCount = createSelector(
//   (state) => state.testimonial.testimonials,
//   (testimonials) => (testimonials || []).length
// );

const selectContactsCount = createSelector(
  (state) => state.contact.contacts,
  (contacts) => (contacts || []).length
);

const selectTariffsCount = createSelector(
  (state) => state.tariff.tariffs,
  (tariffs) => (tariffs || []).length
);

const selectReportsCount = createSelector(
  (state) => state.report.reports,
  (reports) => (reports || []).length
);

const Home = () => {
  const dispatch = useDispatch();

  // Use individual selectors for each count
  const usersCount = useSelector(selectUsersCount);
  const bannersCount = useSelector(selectBannersCount);
  const teamMembersCount = useSelector(selectTeamMembersCount);
  const servicesCount = useSelector(selectServicesCount);
  const faqsCount = useSelector(selectFaqsCount);
  // const testimonialsCount = useSelector(selectTestimonialsCount);
  const tariffsCount = useSelector(selectTariffsCount);
  const reportsCount = useSelector(selectReportsCount);
  const contactsCount = useSelector(selectContactsCount);

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getBanners());
    dispatch(getTeamMembers());
    dispatch(getServices());
    dispatch(getFaqs());
    // dispatch(getTestimonials());
    dispatch(getTariffs());
    dispatch(getReports());
    dispatch(getContacts());
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <Sidebar />
      <div className="content-wrapper">
        <ContentHeader />
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <InfoBox
                bgClass="bg-dark text-white"
                number={usersCount}
                text="Current Users"
              />
              <InfoBox
                bgClass="bg-dark text-white"
                number={bannersCount}
                text="Current Banners"
              />
              <InfoBox
                bgClass="bg-dark text-white"
                number={teamMembersCount}
                text="Current Team Members"
              />
              <InfoBox
                bgClass="bg-dark text-white"
                number={servicesCount}
                text="Current Services"
              />
              <InfoBox
                bgClass="bg-dark text-white"
                number={faqsCount}
                text="Current Faqs"
              />
              {/* <InfoBox
                bgClass="bg-dark text-white"
                number={testimonialsCount}
                text="Current Testimonials"
              /> */}
              <InfoBox
                bgClass="bg-dark text-white"
                number={tariffsCount}
                text="Current Tariffs"
              />
              <InfoBox
                bgClass="bg-dark text-white"
                number={reportsCount}
                text="Current Reports"
              />
              <InfoBox
                bgClass="bg-dark text-white"
                number={contactsCount}
                text="Current Contacts"
              />
            </div>

            {/* Google Maps section remains unchanged */}
            <div className="row">
              <div className="col-md-12">
                <section className="google-map-section pt-5 pb-5">
                  <div className="auto-container">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.795895442069!2d-10.774264196492105!3d6.290535796505146!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xf0a0299304d7471%3A0x88ecaa18e64abbc0!2sAccess%20Bank%20Liberia!5e0!3m2!1sen!2s!4v1723405684807!5m2!1sen!2s"
                      width="600"
                      height="400"
                      frameBorder="0"
                      style={{ border: "0", width: "100%" }}
                      allowFullScreen=""
                      aria-hidden="false"
                      tabIndex="0"
                      referrerPolicy="no-referrer-when-downgrade"
                      title="Google Maps location of Access Bank Liberia"
                    ></iframe>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Home;
