import axios from "axios";
import {
  getTeamMembersSuccess,
  getTeamMemberSuccess,
  createTeamMemberSuccess,
  updateTeamMemberSuccess,
  deleteTeamMemberSuccess,
  teamMemberError,
} from "../reducers/teamMemberReducer";

const API_URL = process.env.REACT_APP_API_URL;

const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  return token ? { Authorization: `Bearer ${token}` } : {};
};

export const getTeamMembers = () => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}/team-members`, {
      headers: getAuthHeader(),
    });
    dispatch(getTeamMembersSuccess(res.data.data));
  } catch (err) {
    dispatch(teamMemberError());
  }
};

export const getTeamMember = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}/team-members/${id}`, {
      headers: getAuthHeader(),
    });
    dispatch(getTeamMemberSuccess(res.data.data));
  } catch (err) {
    dispatch(teamMemberError());
  }
};

export const createTeamMember = (teamMemberData) => async (dispatch) => {
  try {
    const res = await axios.post(`${API_URL}/team-members`, teamMemberData, {
      headers: {
        ...getAuthHeader(),
        "Content-Type": "multipart/form-data",
      },
    });

    if (res.data.success) {
      dispatch(createTeamMemberSuccess(res.data.data));
      // Redirect after successful creation
      window.location.href = "/team-members/view";
    } else {
      throw new Error(res.data.error || "Failed to create team member");
    }
  } catch (err) {
    dispatch(teamMemberError(err.response?.data?.error || err.message));
  }
};

export const updateTeamMember = (id, teamMemberData) => async (dispatch) => {
  try {
    const res = await axios.put(
      `${API_URL}/team-members/${id}`,
      teamMemberData,
      {
        headers: getAuthHeader(),
      }
    );

    if (res.data.success) {
      dispatch(updateTeamMemberSuccess(res.data.data));
    } else {
      throw new Error(res.data.error || "Failed to update team member");
    }
  } catch (err) {
    dispatch(teamMemberError());
  }
};

export const deleteTeamMember = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(`${API_URL}/team-members/${id}`, {
      headers: getAuthHeader(),
    });
    if (res.data.success) {
      dispatch(deleteTeamMemberSuccess(id));
    } else {
      throw new Error(res.data.error || "Failed to delete team member");
    }
  } catch (err) {
    dispatch(teamMemberError());
  }
};
