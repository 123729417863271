import axios from "axios";
import {
  getBannersSuccess,
  getBannerSuccess,
  createBannerSuccess,
  updateBannerSuccess,
  deleteBannerSuccess,
  bannerError,
} from "../reducers/bannerReducer";

const API_URL = process.env.REACT_APP_API_URL;

const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  return token ? { Authorization: `Bearer ${token}` } : {};
};

export const getBanners = () => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}/banners`, {
      headers: getAuthHeader(),
    });
    dispatch(getBannersSuccess(res.data.data));
  } catch (err) {
    dispatch(bannerError());
  }
};

export const getBanner = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}/banners/${id}`, {
      headers: getAuthHeader(),
    });
    dispatch(getBannerSuccess(res.data.data));
  } catch (err) {
    dispatch(bannerError());
  }
};

export const createBanner = (bannerData) => async (dispatch) => {
  try {
    const res = await axios.post(`${API_URL}/banners`, bannerData, {
      headers: {
        ...getAuthHeader(),
        "Content-Type": "multipart/form-data",
      },
    });

    if (res.data.success) {
      dispatch(createBannerSuccess(res.data.data));
      // Redirect after successful creation
      window.location.href = "/banners/view";
    } else {
      throw new Error(res.data.error || "Failed to create banner");
    }
  } catch (err) {
    dispatch(
      bannerError(
        err.response?.data?.error || err.message || "An unknown error occurred"
      )
    );
  }
};

export const updateBanner = (id, bannerData) => async (dispatch) => {
  try {
    const res = await axios.put(`${API_URL}/banners/${id}`, bannerData, {
      headers: getAuthHeader(),
    });

    if (res.data.success) {
      dispatch(updateBannerSuccess(res.data.data));
      window.location = `/banners/view`;
    } else {
      throw new Error(res.data.error || "Failed to update banner");
    }
  } catch (err) {
    dispatch(bannerError());
  }
};

export const deleteBanner = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(`${API_URL}/banners/${id}`, {
      headers: getAuthHeader(),
    });
    if (res.data.success) {
      dispatch(deleteBannerSuccess(id));
    } else {
      throw new Error(res.data.error || "Failed to delete banner");
    }
  } catch (err) {
    dispatch(bannerError());
  }
};
